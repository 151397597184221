export function cpfMask(value) {
    let valueAtual = value.replace(/\D/g, '');

    if (valueAtual.length < 14) {
        valueAtual = valueAtual.replace(/(\d{3})(\d)/,'$1.$2');
        valueAtual = valueAtual.replace(/(\d{3})(\d)/,'$1.$2');
        valueAtual = valueAtual.replace(/(\d{3})(\d{1,2})$/,'$1-$2');
    } else {
        valueAtual = valueAtual.replace(/^(\d{2})(\d)/,'$1.$2');
        valueAtual = valueAtual.replace(/^(\d{2})\.(\d{3})(\d)/,'$1.$2.$3');
        valueAtual = valueAtual.replace(/\.(\d{3})(\d)/,'.$1/$2');
        valueAtual = valueAtual.replace(/(\d{4})(\d)/,'$1-$2');
    }

    return valueAtual;
};

export function telMask(tel) {
    let currentValue = tel.replace(/\D/g, '')
                      .replace(/(.{2})(\d)/, '($1) $2')
                      .replace(/(.{4})(\d)/, '$1 $2')
                      .replace(/(.{4})$/, '-$1');

    if (currentValue.length > 16) {
        currentValue = currentValue.replace(/\D/g, '')
                                   .replace(/(.{2})(\d)/, '+$1 ($2')
                                   .replace(/(.{7})(\d)/, '$1) $2')
                                   .replace(/(.{4})$/, '-$1')
    }

    return currentValue;
};

export function cepMask(cep) {
    let cepAtual = cep.replace(/\D/g,'');

    if (cepAtual.length <= 8) {
        // cepAtual = cepAtual.replace(/(\d{2})(\d)/,'$1.$2');
        cepAtual = cepAtual.replace(/(\d)(\d{3})$/,'$1-$2');
    }

    return cepAtual;
};

export function identification(id) {
    let idAtual = id.replace(/\D/g,'');

    if (idAtual.length <= 9) {
        idAtual = idAtual.replace(/(\d{2})(\d)/,'$1.$2');
        idAtual = idAtual.replace(/(\d{3})(\d)/,'$1.$2');
        idAtual = idAtual.replace(/(\d)(\d{1})$/,'$1-$2');
    }

    return idAtual;
};

export function cardMask(values) {
    let currentValues = values.cardNumber.replace(/\D/g, '');
    let cardName = cardFlags(currentValues);
    let mask;

    if (cardName.toString().toUpperCase() === 'AMEX') {
        values.maxCVC = 4
        return mask = '9999 999999 99999';
    } else if (cardName.toString().toUpperCase() === 'DINERS') {
        values.maxCVC = 3
        return mask = '9999 999999 9999';
    } else {
        values.maxCVC = 3
        return mask = '9999 9999 9999 9999';
    }
};

export function cardFlags(number) {
    let cardNumber = number.replace(/[^0-9]+/g, '');

    let cards = {
        AMEX: /^3[47][0-9]{5,}$/, //AMEX
        
        Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        
        Elo: /^(4011|431274|438935|451416|457393|4576|457631|457632|504175|50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|627780|636297|636368|636369|(6503[1-3])|(6500(3[5-9]|4[0-9]|5[0-1]))|(6504(0[5-9]|1[0-9]|2[0-9]|3[0-9]))|(650(48[5-9]|49[0-9]|50[0-9]|51[1-9]|52[0-9]|53[0-7]))|(6505(4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-8]))|(6507(0[0-9]|1[0-8]))|(6507(2[0-7]))|(650(90[1-9]|91[0-9]|920))|(6516(5[2-9]|6[0-9]|7[0-9]))|(6550(0[0-9]|1[1-9]))|(6550(2[1-9]|3[0-9]|4[0-9]|5[0-8]))|(506(699|77[0-8]|7[1-6][0-9))|(509([0-9][0-9][0-9])))/,
        
        Discover: /^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])[0-9]{0,}$/,
        
        JBC: /^(?:2131|1800|35\d{3})\d{11}$/, //JBC
        
        Maestro: /^(5[06789]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]/, //Maestro
        
        MasterCard: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]/, //MasterCard

        Visa: /^4\d{5}/, //Visa
    };

    for (let flag in cards) {
        if (cards[flag].test(cardNumber)) {
            return flag;
        }
    }

    return false;
};
  
  
  