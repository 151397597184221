import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';

import { logOut } from '../utils/services/access';

import Helmet from 'react-helmet';
import Footer from '../components/Footer';

import layoutPage from '../theme/pageThemes';
import appMaintenance from '../utils/services/appMaintenance';
import { androidHref, appleHref } from '../utils/services/urls';

export default function Maintenance() {
    const theme = layoutPage();
    const navigate = useNavigate();
    
    async function maintenance() {
        try {
            const app = await appMaintenance();

            if (!app) {
                logOut();
                navigate('/app/eventos');
            }
        } catch {
            sessionStorage.setItem('error', true);
            navigate('/app/erro-inesperado')
        }
    }

    useEffect(() => {
        maintenance();
    }, []);
    
    return (
        <>
            <Helmet>
                <title>Manutenção | Urbanky</title>
            </Helmet>
                        <>
                            <Box className={theme.containerErrorPages}>
                                <Container maxWidth="md" sx={{ backgroundColor: '#111' }}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img
                                            alt="Under development"
                                            src="/img/maintenance.svg"
                                            style={{
                                            display: 'inline-block',
                                            maxWidth: '100%',
                                            width: 360
                                            }}
                                        />
                                    </Box>

                                    <Typography
                                        variant="h1"
                                        color="secondary"
                                        align="center"
                                        mt={5}
                                    >
                                        Estamos em manutenção!
                                    </Typography>
                                    
                                    <Typography
                                        variant="subtitle2"
                                        color="text.ternary"
                                        align="center"
                                        fontSize="1.1em"
                                        mt={2}
                                    >
                                        Estamos trabalhando para fazer o seu Urbanky ainda melhor.
                                    </Typography>

                                    <Box
                                        sx={{
                                            mt: 2,
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            justifyContent:'center',
                                            gap: '10px'
                                        }}
                                    >
                                        <Button
                                            href={appleHref}
                                            target="_blank"
                                            sx={{ p: 0 }}
                                        >
                                            <img
                                                height={50}
                                                alt="Logo"
                                                src="/img/apple.svg"
                                            />
                                        </Button>

                                        <Button
                                            href={androidHref}
                                            target="_blank"
                                            sx={{ p: 0 }}
                                        >
                                            <img
                                                height={50}
                                                alt="Logo"
                                                src="/img/android.svg"
                                            />
                                        </Button>
                                    </Box>

                                </Container>
                            </Box>

                            <Footer />
                        </>
        </>
    )
}