import { useEffect, useState } from 'react';
import { Box, IconButton, MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import Cards from 'react-credit-cards';
import FavoriteCards from './FavoriteCards';

export default function SlideCards(props) {
    const {
        handleChange,
        handleClose,
        favorite,
        setLoading,
        setRefresh,
        setScheme,
        values
    } = props;
    
    const maxSteps = favorite.length + 1;
    const [activeStep, setActiveStep] = useState(0);

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function clearValues() {
        for (let field in values) {
            const e = document.getElementById(field);
            const currentField = { target: { name: field } };
            
            if (field === 'useFavorite') {
                currentField.target.value = false;
                handleChange(currentField);
            }

            if (e) {
                e.value = '';
                currentField.target.value = e.value;
                handleChange(currentField);
            }
            
        }
        
        handleClose();
    }

    function fillField(card) {
        values.card_id = card._id

        for (let field in values) {
            const e = document.getElementById(field);
            const currentField = { target: { name: field } };
            
            if (field === 'useFavorite') {
                currentField.target.value = true;
                handleChange(currentField);
            }

            if (e) {
                switch (field) {
                    case 'cardExpiry':
                        currentField.target.value = `${card.expiry_month}/${card.expiry_year.substring(2, 4)}`;
                        break;
                    
                    case 'cardNumber':
                        currentField.target.value = card.pan_obscure;
                        break;
    
                    case 'holderName':
                        currentField.target.value = card.card_holder_name;
                        break;
    
                    case 'cardSave':
                        currentField.target.value = false;
                        break;
    
                    default:
                        currentField.target.value = '';
                        e.value = ''
                        break;
                }
                
                handleChange(currentField);
            }
        }
    }

    useEffect(() => {
        if (favorite.length < 1) {
            clearValues();
            setActiveStep(0);
            setScheme('');
        }

    }, [favorite]);
    
    return (
        <Box
            sx={{
                width: 290,
                flexGrow: 1,
                m: '0 auto',

                '@media (max-width: 400px)': { width: 200 }
            }}
        >
            <Box>
                {
                    activeStep < 1
                        ?
                            <Cards
                                number={values.cardNumber}
                                name={values.holderName}
                                expiry={values.cardExpiry}
                                placeholders={{ name: 'NOME NO CARTÃO' }}
                                locale={{ valid: 'Validade' }}
                                cvc=""
                            />
                        : favorite < 1
                            ? null
                            :
                                <FavoriteCards
                                    card={favorite[activeStep - 1]}
                                    setLoading={setLoading}
                                    setRefresh={setRefresh}
                                    setScheme={setScheme}
                                />
                }
            </Box>

            <MobileStepper
                disabled={values.cardSave}
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <IconButton
                        disabled={activeStep === maxSteps - 1 || values.cardSave}
                        size="small"
                        onClick={() => {
                            fillField(favorite[activeStep]);
                            handleNext();
                        }}
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                }
                backButton={
                    <IconButton
                        disabled={activeStep === 0 || values.cardSave}
                        size="small"
                        onClick={() => {
                            activeStep - 1 > 0
                                ? fillField(favorite[activeStep - 2])
                                : clearValues()
                                
                            handleBack();
                        }}
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                }
                sx={{ gap: .5 }}
            />
        </Box>
    );
}