export default function dataEvent(eventDate, event) {
    const data = {
        id: event._id,
        client_token: event.client_token,
        event_date_id: eventDate,
        event_name: event.event_name,
        event_description: event.event_description,
        fee_convenience: event.fee_convenience,
        installments: event.installments,
        indicative_age: event.indicative_age,
        pictures: event.pictures,
        has_campaing: event.setting.has_campaing,
        has_coupom: event.setting.has_coupom,
        has_pcd: event.setting.has_pcd,
    };

    sessionStorage.setItem('event', JSON.stringify(data));
}