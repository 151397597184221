import { createContext, useContext, useRef, useState } from 'react';
import { useErrors } from '../../utils/hooks/useErrors';

import { Grid, TextField } from '@mui/material';
import { SnackbarAlert, triggerAlert } from '../ShowAlerts';

import { codeValidation } from '../../utils/validations/userValidations';

import InputMask from 'react-input-mask';

export default function ValidateCode({ handleChange, setValidations, values }) {
    const componentRef = useRef();

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(createContext({ code: codeValidation }));

    const [errors, fieldsValidations] = useErrors(validations);

    function handleOpenAlert(text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            text: text
        });
    }

    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false
        });
    }

    function handleBlur(e) {
        setValidations(validations);
        fieldsValidations(e);
        triggerAlert(e, validations, handleOpenAlert, handleCloseAlert);
    }

    return (
        <>
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <InputMask
                        mask={'9999'}
                        maskChar={null}
                        value={values.code}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        {
                            () =>
                                <TextField
                                    fullWidth
                                    error={!errors.code.valid}
                                    id="code"
                                    name="code"
                                    variant="outlined"
                                    placeholder="0000"
                                    sx={{
                                        width: 86,
                                        height: 42,
                                        
                                        '& fieldset': { borderRadius: 2 },
                    
                                        '& input': {
                                            padding: '10px 16px',
                                            textAlign: 'center'
                                        },
                                    }}
                                />
                        }
                    </InputMask>
                </Grid>
            </Grid>
        </>
    );
}