import {  useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';

import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import AppNavbar from './AppNavbar';

const AppLayoutRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
}));

const AppLayoutWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    transition: '.75s all',
    paddingTop: 64
}));

const AppLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

const AppLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    background: 'center no-repeat url(/img/banners/bg-tempo.webp)',
    backgroundSize: 'cover'
});

export default function AppLayout() {
    const [active, setActive] = useState(false);
    const [logged, setLogged] = useState(false);
    const [isMobileNavOpen, setMobileNavOpen] = useState(true);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            window.pageYOffset > 299 ? setActive(true) : setActive(false);
        });
    }, []);

    return (
        <AppLayoutRoot>
            <AppNavbar
                active={active}
                logged={logged}
                onLogged={setLogged}
                onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)}
            />
            
            <MainSidebar
                logged={logged}
                onLogged={setLogged}
                openMobile={isMobileNavOpen}
                onMobileClose={() => setMobileNavOpen(false)}
            />
            <AppLayoutWrapper>
                <AppLayoutContainer>
                    <AppLayoutContent>
                        <Outlet />
                    </AppLayoutContent>
                </AppLayoutContainer>
            </AppLayoutWrapper>
        </AppLayoutRoot>
    );
}