import { makeStyles } from '@mui/styles';
import imgBack from '../components/images/background.png';

const layoutPage = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },

    boxContainer: {
        margin: 0,
        padding: 0,
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${imgBack})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },

    homeContainer: {
        padding: 0,
        margin: '0 auto',
        maxWidth: '100vw',
        borderRadius: 0,
        backgroundColor: '#00000000'
    },

    slideContainer: {
        width: '100%',
        maxWidth: 610,
        borderRadius: 16,
        height: 'inherit',
        position: 'relative',
        backgroundColor: '#F5F5F5',
        boxShadow: '0 0 15px #ccff00'
    },

    payContainer: {
        padding: 40,
        width: '70%',

        '@media (max-width: 768px)': { width: '90%' },
        '@media (max-width: 355px)': { padding: '40px 15px' }
    },

    parcel: {
        width: 290,
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    
        '& div': { padding: '4px 14px' },
    
        '& label': { marginTop: -8 },
    
        '& fieldset': { border: 'none' },
    
        '& input': {
            padding: '0 14px',
            border: 'none !important'
        },
    
        '@media screen and (max-width: 1024px)': { width: '100%' },
    
        '@media screen and (max-width: 390px)': {
            '& label': {
                fontSize: 12,
                marginTop: '-4px'
            }
        },

        '@media screen and (max-width: 280px)': {
            '& label': { fontSize: '.59em' }
        },
    },

    cupom: {
        width: 290,
        borderRadius: '8px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
    
        '& div': { padding: '8px 14px' },
    
        '& label': { marginTop: -8 },
    
        '& fieldset': { border: 'none' },
    
        '& input': {
            padding: '0 14px',
            border: 'none !important'
        },
    
        '@media screen and (max-width: 1024px)': { width: '100%' },
    
        '@media screen and (max-width: 390px)': {
            '& label': {
                fontSize: 12,
                marginTop: '-4px'
            }
        },

        '@media screen and (max-width: 280px)': {
            '& label': { fontSize: '.59em' }
        },
    },

    code: {
        width: 86,
        height: 42,
        
        '& fieldset': { borderRadius: 8 },

        '& input': {
            padding: '10px 16px',
            textAlign: 'center'
        }
    },
    
    cardSlide: {
        boxShadow: 'none',

        '&:hover': {
            color: '#000',
            boxShadow: 'none',
            backgroundColor: '#00000000'
        }
    },

    modalContainer: {
        padding: 24,
        maxWidth: 324
    },

    footerContainer: {
        m: '0 !important',
        borderRadius: 0,
        backgroundImage: `url(${imgBack})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

        '@media (max-width: 414px)': { width: '100%' }
    },
    
    textsFooter: {
        color: 'whitesmoke',
        
        '@media (max-width: 922px)': { textAlign: 'right' },
        '@media (max-width: 747px)': { textAlign: 'left' }
    },

    containerErrorPages: {
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },

    boxStore: {
        width: 110,
        marginTop: 10,
        cursor: 'pointer'
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },

    img: {
        width: '100%',
        height: '100%',
        display: 'block',
        overflow: 'hidden',
    },

    titlePage: {
        color: '#cdff00',
        fontSize: 60,
        textAlign: 'center',
        marginTop: '3%',

        '@media (max-width: 539px)': { fontSize: 40 }
    },

    subTitlePage: {
        color: '#fc0065',
        fontSize: '30px !important',
        textAlign: 'center',
        marginTop: '3% !important',
    },

    // Botões
    btnSubmit: {
        '@media screen and (max-width: 599px)': {
            width: '100%',
        },
    },

    btnSoon: {
        pointerEvents: 'none'
    },

    btnSlide: {
        padding: 2,
        width: '40%',
        boxShadow: 'none',
        fontSize: 'min(30px, 5vw)',

        '&:hover': {
            background: '#FC0065',
            color: '#F5F5F5',
            boxShadow: `0 0 5px #FC0065,
                         0 0 10pc #FC0065,
                         0 0 15px #FC0065,
                         0 0 20px #FC0065,
                         0 0 25px #FC0065`
        }
    },

    btnCards: {
        padding: '4px 8px',
        height: 27,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'none',
        borderRadius: '4px !important',

        '&:hover': { cursor: 'pointer' }
    },

    btnGratuity: {
        padding: '4px 8px',
        height: 27,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'none',
        pointerEvents: 'none',
        borderRadius: '4px !important',

        '&:hover': { cursor: 'pointer' }
    },

    //ModalBuyEvent BuyEvent ModalDownloadVoucher
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.ternary,
    },

    boxInfo: {
        display: 'flex',
        padding: '4px 0px',
        alignItems: 'center',
        // borderRadius: 4,
        // backgroundColor: '#999',
        // boxShadow: '0px 2px 4px #00000029',
        gap: 8
    },

    //boarding
    boarding: {
        marginTop: '8px !important',
        backgroundColor: '#fff !important',
        borderRadius: '40px !important',
        // maxWidth: 190,

        '& #select': {
            padding: '10px 14px !important',
            margin: '0 auto !important'
        },

        '& label': { top: -6 },
        
        '@media screen and (max-width: 450px)': { width: '100%' },
        
        '@media screen and (max-width: 320px)': {
            '& label': {
                fontSize: '15px !important',
                marginTop: '1px'
            },

            '& #select': { fontSize: 14 }
        },

        '@media screen and (max-width: 280px)': {
            '& label': {
                fontSize: '13px !important',
                marginTop: '2px'
            }
        }
    },

    research: {
        marginTop: '8px !important',
        backgroundColor: '#fff',
        borderRadius: '40px',
    
        '& fieldset': { border: 'none' },
        '& input': { padding: 8 },
        
        '@media screen and (max-width: 450px)': {
            width: '100%',
    
            '& svg': { marginLeft: 0 },
            '& input': {
                fontSize: '.9em',
                padding: '8px 0'
            }
        },
        
        '@media screen and (max-width: 310px)': {
            '& input': { fontSize: '.8em' }
        },
    },

    navPath: {
        fontSize: 15,
        width: '100%',
        fontWeight: 400,
        borderRadius: 8,
        boxShadow:'none',
        padding: '8px 24px',
        transition: '.75s all',
        textTransform: 'uppercase',

        '&:hover': {
            color: '#000',
            backgroundColor: '#cdff00'
        }
    },

    navLogin: {
        fontSize: 15,
        color: '#000',
        width: '100%',
        fontWeight: 400,
        borderRadius: 8,
        boxShadow:'none',
        padding: '8px 24px',
        transition: '.75s all',
        textTransform: 'uppercase',
        backgroundColor: '#cdff00',

        '&:hover': {
            color: '#000',
            backgroundColor: '#cdff00'
        }
    },

    navIcon: {
        width: 32,
        height: 32,
        fontSize: 16,
        fontWeight: 400,
        transition: '.75s all',

        '&:hover': {
            color: '#000',
            backgroundColor: '#cdff00'
        }
    }
}));

export default layoutPage;
