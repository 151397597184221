import { Backdrop } from '@mui/material';
import { useLottie } from 'lottie-react';
import loadingU from './images/loadingU.json';

export default function LoadingU({ loading }) {
    const options = {
        animationData: loadingU,
        loop: true,
        autoplay: true
    };

    const { View } = useLottie(options, { width: 50 });

    return (
        <Backdrop
            open={loading}
            sx={{
                color: '#fff',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
        >
            {View}
        </Backdrop>
    )
}