import axios from 'axios';

const timeout = 5000;

export const sendSMS = async (obj) => {
    const fields = JSON.stringify({
        data: obj.choise,
        type: obj.sessionType
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/security/send/token`,
        method: 'post',
        timeout: timeout,
        data: fields,

        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'application/json'
        }
    };

    return await axios(config)
        .then(() => true)
        .catch(() => false);
}

export async function sendCode(obj) {
    const fields = JSON.stringify({
        document: !obj.docSelect ? obj.cpf_passport.replace(/\D/g, '') : obj.cpf_passport,
        contact: !obj.sendSelect ? obj.tel_email.replace(/\D/g, '') : obj.tel_email
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v2/user/${obj.sessionType}`,
        method: 'post',
        timeout: timeout,
        data: fields,
        
        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'application/json'
        }
    };
    
    return await axios(config)
        .then(() => true)
        .catch(() => false);
}

export async function validateCodeCel(obj) {
    const sendSelect = () => {
        if (obj.sendSelect !== undefined) {
            if (!obj.sendSelect) return obj.tel_email.replace(/\D/g, '');
            else return obj.tel_email;

        } else return obj.choise
    };

    const fields = JSON.stringify({
        data: sendSelect(),
        token: obj.code,
        type: obj.sessionType
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/security/validation/token`,
        method: 'post',
        timeout: timeout,
        data: fields,
        
        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'application/json'
        }
    };

    return await axios(config)
        .then(() => true)
        .catch(() => false);
}

export async function changePassword(obj) {
    const fields = JSON.stringify({
        data: !obj.sendSelect ? obj.tel_email.replace(/\D/g, '') : obj.tel_email,
        new_password: obj.newPassword,
        token: obj.code,
        type: obj.sessionType,
        username: !obj.docSelect ? obj.cpf_passport.replace(/\D/g, '') : obj.cpf_passport
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v2/user/change_password/token`,
        method: 'put',
        timeout: timeout,
        data: fields,
        
        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'application/json'
        }
    }
    
    return await axios(config)
        .then(() =>  {
            return {
                valid: true,
                confirm: 'Senha alterada com sucesso!',
                info: 'Você também pode acessar o Urbanky no seu celular, baixe o app!'
            }
        })
        .catch(() => {
            return {
                valid: false,
                text: 'Houve um erro, tente novamente mais tarde!'
            }
        });
}