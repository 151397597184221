import { Box } from '@mui/material';
import { useEffect } from 'react';
import Cards from 'react-credit-cards';
import ModalDeleteCard from './ModalDeleteCard';


export default function FavoriteCards({ card, setLoading, setRefresh, setScheme }) {

    useEffect(() => {
        const creditCard = document.querySelector('.rccs__card')
        creditCard.classList.remove('rccs__card--unknown')
        creditCard.classList.remove(`rccs__card--${card.scheme.toLowerCase()}`)
        creditCard.classList.add(`rccs__card--${card.scheme.toLowerCase()}`)

        setScheme(card.scheme)
    }, [card._id])

    return (
        <Box sx={{ position: 'relative', backgroundColor: 'transparent' }}>
            <ModalDeleteCard
                card={card}
                setLoading={setLoading}
                setRefresh={setRefresh}
            />

            <Cards
                key={card._id}
                number={card.pan_obscure}
                name={card.card_holder_name}
                expiry={`${card.expiry_month}${card.expiry_year.substring(2, 4)}`}
                placeholders={{ name: 'NOME NO CARTÃO' }}
                locale={{ valid: 'Validade' }}
                cvc=""
            />
        </Box>
    )
}