import { Navigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import AppLayout from './components/AppLayout';
// import Home from './pages/Home';
import Login from './pages/Login';
import Events from './pages/Events';
import BuyVoucher from './pages/BuyVoucher';
import Payment from './pages/Payment';
import Vouchers from './pages/Vouchers';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import UnexpectedError from './pages/UnexpectedError';
import SuccessConfirmation from './pages/SuccessConfirmation';
import RecoverPassword from './pages/RecoverPassword';
import Register from './pages/Register';
import EventsClone from './pages/EventsClone';

export const routes = [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            { path: '/', element: <Navigate to='/app/eventos' /> },
            { path: '/login', element: <Navigate to='/app/login' /> },
            { path: '*', element: <Navigate to='/app/404' /> },
        ]
    },
    {
        path: 'app',
        element: <AppLayout />,
        children: [
            // { path: 'home', element: <Home /> },
            { path: 'login', element: <Login /> },
      
            { path: 'eventos', element: <Events /> },
            { path: 'eventos-clone', element: <EventsClone /> },
            { path: 'eventos/comprar-voucher', element: <BuyVoucher /> },
            // { path: 'eventos/comprar-voucher/donates', element: <Donates /> },
      
            { path: 'vouchers', element: <Vouchers /> },
      
            { path: 'pagamento', element: <Payment /> },
            { path: 'pagamento/confirmacao', element: <SuccessConfirmation /> },
      
            { path: 'cadastrar', element: <Register /> },
      
            { path: 'recuperar-senha', element: <RecoverPassword /> },
            { path: 'recuperar-senha/confirmacao', element: <SuccessConfirmation />},
      
            { path: 'manutencao', element: <Maintenance /> },
            { path: 'erro-inesperado', element: <UnexpectedError /> },
            { path: '404', element: <NotFound /> }
        ]
    }
];