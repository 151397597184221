import { Add, Remove } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";

const VoucherQTY = forwardRef((props, ref) => {
    const { fee_convenience, handleChange, ticket, values } = props;
    const [qty, setQty] = useState(0);
    
    values.tickets.ticket_id = ticket._id;
    
    function sum() { setQty(prevState => prevState + 1); }
    function decrease() { qty < 1 ? setQty(0) : setQty(prevState => prevState - 1); }

    useEffect(() => {
        document.querySelectorAll('.qty').forEach((item, i) => item.innerHTML = values.tickets?.quantity[i] || 0);
    }, []);

    useEffect(() => {
        if (qty >  0) {
            let quantity = 0;
            const event = {
                target: {
                    name: 'amount',
                    value: values.amount + (ticket.price)
                }
            };

            values.tickets.fee_convenience = ticket.price * (fee_convenience / 100);
            values.tickets.quantity = [];

            document.querySelectorAll('.qty').forEach(item => {
                values.tickets.quantity.push(item.innerHTML);
                quantity = quantity + item.innerHTML;
            });

            values.tickets.qty = quantity;

            handleChange(event);
        }
    }, [qty]);

    return (
        <Grid
            container
            ref={ref}
            display="flex"
            justifyContent="space-between"
            sx={{
                '@media screen and (max-width: 599px)': {
                    justifyContent: 'center'
                }
            }}
        >
            <Grid
                item
                xs={12}
                sm={4}
                display="flex"
                sx={{
                    gap: 1,
                    flexDirection: 'column',

                    '@media screen and (max-width: 599px)': {
                        mb: 1,
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }
                }}
            >
                <Typography color="primary" variant="h5">
                    {ticket.title}
                </Typography>
                <Typography color="primary" variant="h5">
                    {
                        `${parseFloat(ticket.price).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        })}`
                    }
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={8}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{
                    '@media screen and (max-width: 599px)': { justifyContent: 'center' }
                }}
            >
                <Box
                    fullWidth
                    sx={{
                        border: '1px solid #999',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 2,
                        p: '4px 0',
            
                        '@media screen and (max-width: 280px)': {
                            p: '4px',
                            width: '100%',
                            justifyContent: 'space-around'
                        }
                    }}
                >
                    <IconButton onClick={decrease}>
                        <Remove />
                    </IconButton>
                    <Typography
                        className="qty"
                        color="primary"
                        variant="h3"
                        sx={{
                        color: "#333",
                        padding: "0 20px",
                        textAlign: "center",

                            '@media screen and (max-width: 280px)': {
                                padding: 0
                            }
                        }}
                    >
                        {qty}
                    </Typography>
                    <IconButton onClick={sum}>
                        <Add />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    );
});

export default VoucherQTY;