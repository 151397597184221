import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Drawer, Hidden, IconButton, List, ListItem } from '@mui/material';

import { login, logOut } from '../utils/services/access';

import { NavItem, NavItemLink } from './NavItem';
import { Close, Input } from '@mui/icons-material';
import layoutPage from '../theme/pageThemes';
import ModalExit from './ModalExit';

const items = [
    { href: 'https://urbanky.com.br/', title: 'home' },
    { href: '/app/eventos', title: 'eventos' },
    { href: 'https://urbanky.com.br/faq.html', title: 'dúvidas' },
    { href: 'https://urbanky.com.br/contato.html', title: 'contato' },
    { href: '/app/vouchers', title: 'vouchers' }
];

export default function MainSidebar({ logged, onLogged, onMobileClose, openMobile }) {
    const theme = layoutPage();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        onLogged(login());
    });

    const content = (
        <Hidden mdUp>
            <Box
                sx={{
                    p: '8px 0',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#000'
                }}
            >
                <IconButton
                    color="ternary"
                    onClick={onMobileClose}
                    sx={{
                        m: '24px 32px 24px 24px',
                        alignSelf: 'flex-end'
                    }}
                >
                    <Close />
                </IconButton>

                <List sx={{ width: '100%' }}>
                    {
                        items.map((item, i) => (
                            <Fragment key={i}>
                                {
                                    item.title !== 'vouchers'
                                        ? <NavItemLink href={item.href} theme={theme.navPath} title={item.title} />
                                        : logged &&
                                            <NavItem href={item.href} theme={theme.navPath} title={item.title} />
                                }
                            </Fragment>
                        ))
                    }
                </List>
                
                {
                    logged && <ModalExit />
                }
            </Box>
        </Hidden>
    );

    useEffect(() => {
        if (openMobile && onMobileClose) onMobileClose();
    }, [location.pathname]);

    return (
        <Hidden mdUp>
            <Drawer
                anchor="top"
                variant="temporary"
                open={openMobile}
                onClose={onMobileClose}
                PaperProps={{ sx: { width: '100%' } }}
            >
                {content}
            </Drawer>
        </Hidden>
    );
}