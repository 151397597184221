import axios from "axios";
import moment from "moment/moment";

const timeout = 5000;

export async function listEvents() {
    const pathname = window.location.pathname.split('/');
    const type = pathname[pathname.length - 1] !== 'eventos-clone'
                    ? 'Ativo'
                    : 'teste';

    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/event?status=${type}`,
        method: 'get',
        timeout: timeout,
        headers: {}
    };

    return await axios(config)
        .then(({ data }) => data.data)
        .catch(() => false);
}

export async function eventData(event_id) {
    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/view/event?event_id=${event_id}`,
        method: 'get',
        timeout: timeout,
        headers: {}
    };

    return await axios(config)
        .then(({ data }) => {
            // const now = moment().format('YYYY/MM/DD');
            // const dates = data.data.dates.forEach(date => moment(date.begin_date.substr(0, 10), 'YYYY/MM/DD') > now);
            // return dates;

            return data.data;
        })
        .catch(({ response }) => response.data.data);
}

export async function eventTicket(event_id) {
    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/ticket?event_id=${event_id}`,
        method: 'get',
        timeout: timeout,
        headers: {}
    };

    return await axios(config)
        .then(({ data }) => data.data)
        .catch(({ response }) => response.data.data);
}

export async function eventTransport(event_id) {
    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/transport/boarding_point?event_id=${event_id}`,
        method: 'get',
        timeout: timeout,
        headers: {}
    };

    return await axios(config)
        .then(({ data }) => data.data)
        .catch(({ response }) => response.data.data);
}

export async function eventDateTransport(event_id, event_date_id, requestSeats = '') {
    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/view/transport?event_id=${event_id}&event_date_id=${event_date_id}&requestSeats=${requestSeats}`,
        method: 'get',
        timeout: timeout,
        headers: {}
    };

    return await axios(config)
        .then(({ data }) => data.data)
        .catch(({ response }) => response.data.data);
}

export async function listCampaign(event_id) {
    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/campaing?event_id=${event_id}`,
        method: 'get',
        timeout: timeout,
        header: {}
    };

    return await axios(config)
        .then(({ data }) => data.data)
        .catch(({ response }) => response.data.data);
}