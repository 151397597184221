import { Box, Button, Container, Typography } from '@mui/material';

import Footer from '../Footer';

import layoutPage from '../../theme/pageThemes';
import { androidHref, appleHref } from '../../utils/services/urls';

export default function NoEvents() {
    const theme = layoutPage();
    
    return (
        <>
            <Container
                sx={{
                    m: 0,
                    p: '0 0 80px !important',
                    maxWidth: '100vw !important',
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    borderRadius: 0,
                    backgroundColor: '#111',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: 'min(640px, 75vw)',
                        display: 'flex',
                        justifyContent: 'center',
                        background: 'center no-repeat url(../img/banners/city.png)',
                        backgroundSize: 'cover'
                    }}
                >
                    <Box
                        sx={{
                            width: 'min(1146px, 100%)',
                            height: '100%',
                            background: 'center no-repeat url(../img/banners/no-events.jpg)',
                            backgroundSize: 'cover',

                            '@media (max-width: 990px)': {
                                backgroundPositionX: 'left'
                            }
                        }}
                    />
                </Box>
                
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        color="text.ternary"
                        align="center"
                        fontSize="1.1em"
                    >
                        Baixe o nosso app.
                    </Typography>

                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent:'center',
                            gap: '10px'
                        }}
                    >
                        <Button
                            href={appleHref}
                            target="_blank"
                            sx={{ p: 0 }}
                        >
                            <img
                                height={50}
                                alt="Logo"
                                src="/img/apple.svg"
                            />
                        </Button>

                        <Button
                            href={androidHref}
                            target="_blank"
                            sx={{ p: 0 }}
                        >
                            <img
                                height={50}
                                alt="Logo"
                                src="/img/android.svg"
                            />
                        </Button>
                    </Box>
                </Box>

            </Container>

            <Footer />
        </>
    )
}