import { useRef, useState } from 'react';

import {
    Box,
    Container,
    Divider,
    Grid,
    InputAdornment,
    SvgIcon,
    TextField,
    Typography
} from '@mui/material';

import { Search } from '@mui/icons-material';

import Footer from '../Footer';
import ModalBuyEvent from '../ModalBuyEvent';
import AccordionInfos from './AccordionInfos';

import search from '../../utils/fx/search';
import layoutPage from '../../theme/pageThemes';
import getEventImage from '../../utils/fx/getEventImage';
import SlideImages from './SlideImages';



export default function WithEvents({ events }) {
    const theme = layoutPage();
    const componentRef = useRef();
    const [research, setResearch] = useState('');
    
    return (
        <>
            {
                events &&
                    <Box
                        display="flex"
                        alignItems="center"
                        gap="min(60px, 13vw)"
                        flexDirection="column"
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: 'calc(80vh - 64px)',
                                maxHeight: 744,
                                background: 'center no-repeat',
                                padding: '16px 0 min(24px, 5vw)',
                                backgroundImage: 'url(../img/banners/city.png)',
                                backgroundSize: 'cover',

                                '@media (max-height: 768px) and (width: 1366px)': {
                                    height: 680,
                                }
                            }}
                        >
                            <SlideImages events={events} />
                        </Box>

                        <Container
                            maxWidth="md"
                            sx={{
                                p: '0 !important',
                                m: '40px auto',
                                minHeight: 'calc(65vh - 64px)',
                                backgroundColor: 'transparent',
                            }}
                        >
                            <Grid
                                container
                                justifyContent="center"
                                position="relative"
                                width="100%"
                                m="0 auto"
                                sx={{
                                    '@media (max-width: 1024px)': { width: '90%' },
                                    '@media (max-height: 768px)': { mt: '7.5vh' }
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    mb={10}
                                    sx={{
                                        '@media (max-width: 915px)': { mb: 2.5 },
                                        '@media (max-height: 667px)': { padding: '16px 0 min(24px, 5vw)' }
                                    }}
                                >
                                    <Typography className={theme.titlePage} variant="h2">
                                        Eventos Urbanky
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    mb={10}
                                    sx={{
                                        '@media (max-width: 915px)': { mb: 5 }
                                    }}
                                >
                                    <Box sx={{ m: '0 auto', maxWidth: 380 }}>
                                        <Typography
                                            fontSize={20}
                                            color="whitesmoke"
                                            textAlign="center"
                                            mb={1}
                                        >
                                            Busque pelos seus shows favoritos:
                                        </Typography>
                                        <TextField
                                            className={theme.research}
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Digite aqui o nome do evento"
                                            value={research}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon fontSize="small" color="action" sx={{ ml: 5 }}>
                                                            <Search />
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onChange={(e) => setResearch(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        events.map((event, i) => {
                                            const  imgURL = getEventImage(event.pictures, 'detail') || getEventImage(event.pictures, 'banner');
                                            
                                            return (
                                                <Grid
                                                    key={i}
                                                    container
                                                    spacing={3}
                                                > 
                                                    {
                                                        !search(research, event.event_name)
                                                            ? null
                                                            :
                                                                <>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={5}
                                                                        mb={6}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="flex-start"
                                                                        sx={{
                                                                            '@media (max-width: 599px)': {
                                                                                gap: 5,
                                                                                m: '0 auto',
                                                                                alignItems: 'center'
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                height: 168,
                                                                                width: '100%',
                                                                                maxWidth: 336,
                                                                                mb: 4,

                                                                                '@media (max-width: 839px)': { height: 110 },

                                                                                '@media (max-width: 599px)': {
                                                                                    m: '0 auto',
                                                                                    height: 168
                                                                                },

                                                                                '@media (max-width: 350px)': { height: 110 }
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    borderRadius: 12,
                                                                                    backgroundSize: 'cover',
                                                                                    backgroundPosition: 'center',
                                                                                    backgroundRepeat: 'no-repeat',
                                                                                    backgroundImage: `url(${imgURL})`,
                                                
                                                                                    '&:hover': { boxShadow: '0px 0px 15px #cdff0599 !important' },
                                                                                }}
                                                                            />
                                                                        </Box>

                                                                        <ModalBuyEvent
                                                                            className={!event.setting.has_sale ? theme.btnSoon : theme.btnSubmit}
                                                                            size="large"
                                                                            event={event}
                                                                            variant="contained"
                                                                            color={!event.setting.has_sale
                                                                                ? 'soon'
                                                                                : 'secondary'
                                                                            }
                                                                            title={!event.setting.has_sale
                                                                                ? 'Em breve'
                                                                                : !event.setting.has_gratuity
                                                                                    ? 'Comprar'
                                                                                    : 'Gratuito'
                                                                            }
                                                                            style={event.setting.has_sale
                                                                                ? { maxWidth: 336 }
                                                                                :  {
                                                                                        maxWidth: 336,

                                                                                        '@media screen and (max-width: 599px)': {
                                                                                            width: '100%',
                                                                                        },
                                                                                    }
                                                                            }
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={7}>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                                width: '100%'
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="h3"
                                                                                color="whitesmoke"
                                                                                fontSize={32}
                                                                                sx={{ '@media (max-width: 539px)': { fontSize: 24 } }}
                                                                            >
                                                                                {event.event_name.toUpperCase()}
                                                                            </Typography>
                                                                            <Box sx={{ mt: 1 }}>
                                                                                <AccordionInfos ref={componentRef} event={event} />
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12} mb={7}>
                                                                        {
                                                                            (i + 1) >= events.length
                                                                                ? null
                                                                                : <Divider sx={{ borderColor: 'whitesmoke' }} />
                                                                        }
                                                                    </Grid>
                                                                </>
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Container>
                        <Footer />
                    </Box>
            }
        </>
    )
}