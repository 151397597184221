export const weekDay = (dateEvent = '') => {
    if (dateEvent === '') return '';
  
    let week = [
        'DOM',
        'SEG',
        'TER',
        'QUA',
        'QUI',
        'SEX',
        'SAB',
    ];
  
    const arrDate = dateEvent.split('/').reverse();
    const date = new Date(arrDate[0], arrDate[1] - 1, arrDate[2]);
    const day = date.getDay();
  
    return week[day];
};
  
export const getMonth = (el) => {
    let num = el.begin_date.substr(3, 2);
    let month;

    switch (num) {
        case '01':
            month = 'JAN';
            break;

        case '02':
            month = 'FEV';
            break;

        case '03':
            month = 'MAR';
            break;

        case '04':
            month = 'ABR';
            break;

        case '05':
            month = 'MAI';
            break;

        case '06':
            month = 'JUN';
            break;

        case '07':
            month = 'JUL';
            break;

        case '08':
            month = 'AGO';
            break;

        case '09':
            month = 'SET';
            break;

        case '10':
            month = 'OUT';
            break;

        case '11':
            month = 'NOV';
            break;

        default:
            month = 'DEZ';
            break;
    }
  
    return month;
};

export const sortDate = (a, b) => a.begin_date.substr(0, 2) < b.begin_date.substr(0, 2) ? -1 : 1;