import { Divider, Typography } from "@mui/material";

export const showTime = (minutes, seconds, email) => {
    const host = email.split('@');

    return (
        <>
            <Typography textAlign="center" sx={{ mb: 1.25 }}>
                Reenviar código em... {minutes}:{seconds}
            </Typography>

            <Typography textAlign="center" sx={{ mb: 1.25, textAlign: 'center' }}>
                {/* Status do código: Código enviado! */}
                {`O código de validação foi enviado para o email ${host[0].substr(0, 3)}******@${host[host.length - 1]}`}
            </Typography>

            <Divider sx={{ m: '10px 0' }} />
        </>
    );
};

export function startCounting(barter, setMinuteCount, setSecondsCount) {
    const countDownDate = new Date();
    const newCountDown = new Date(countDownDate.getTime() + 1 * 60000);

    const x = setInterval(() => {
        const now = new Date().getTime();
        const distance = newCountDown - now;

        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (seconds.toString().length === 1) setSecondsCount('0' + seconds);
        else setSecondsCount(seconds);

        setMinuteCount(minutes);

        if (distance < 0 || barter) {
            clearInterval(x);
            setMinuteCount('0');
            setSecondsCount('00');
        }
    }, 1000);
}