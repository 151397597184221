import axios from "axios";
import { currentType, userValidation } from "../validations/userValidations";

const timeout = 5000;

export function login() {
    let logged = JSON.parse(sessionStorage.getItem('login'));
    if (logged !== null) return logged;
    else return false;
}

export function userData() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    if (user !== null) return user;
    else return {};
}

export async function loginValidation(user, password) {
    let userCurrent = user;
    userValidation(userCurrent);
    if (currentType === 'cpf') userCurrent = user.replace(/\D/g, '');

    const fields = JSON.stringify({
        username: userCurrent,
        password: password,
        device_id: process.env.REACT_APP_DEVICE_ID,
        device_token: process.env.REACT_APP_DEVICE_TOKEN
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/auth`,
        method: 'post',
        timeout: timeout,
        data: fields,

        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'application/json'
        }
    };

    await axios(config)
        .then(({ data }) => {
            sessionStorage.setItem('login', true);
            sessionStorage.setItem('user', JSON.stringify(data.data));
        })
        .catch(() => {
            sessionStorage.setItem('login', false);
        })
}

export function logOut() {
    sessionStorage.clear();
}