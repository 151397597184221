export const pattern = /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i;
export const onlyNumbers = /[0-9]+$/;
export let currentType = 'email';
let password;

export function userValidation(input) {
    if (pattern.test(input)) currentType = 'email';
    else currentType = 'cpf';

    if (currentType === 'email') {
        const email = emailValidation(input);
        return email;

    } else {
        const cpf = cpfValidation(input);
        return cpf;
    }
}

export function docValidation(input) {
    if (input.length < 11) currentType = 'passport';
    else currentType = 'cpf';
    
    if (currentType === 'cpf') {
        const cpf = cpfValidation(input);
        return cpf;

    } else {
        const passport = passportValidation(input);
        return passport;
    }
}

export function tel_emailValidation(input) {
    if (pattern.test(input)) currentType = 'email';
    else currentType = 'tel';

    if (currentType === 'email') {
        const email = emailValidation(input);
        return email;

    } else {
        const tel = telRecoverValidation(input);
        return tel;
    }
}

export function cpfValidation(input) {
    let sum = 0;
    let leftOver;

    input = input.replace(/[^\d]+/g, '');

    if (input === '') return { valid: false, text: 'Campo obrigatório!' }
    else if (input.length !== 11) return { valid: false, text: 'Verifique se digitou o CPF corretamente!' }
    else if (
        input === "00000000000"
        || input === "11111111111"
        || input === "22222222222"
        || input === "33333333333"
        || input === "44444444444"
        || input === "55555555555"
        || input === "66666666666"
        || input === "77777777777"
        || input === "88888888888"
        || input === "99999999999"
        || input === "01234567890"
    ) return { valid: false, text: 'Digite um CPF válido!' }

    for (let i = 0; i < 9; i++) sum = sum + parseInt(input.substring(i, i + 1)) * (10 - i);

    leftOver = (sum * 10) % 11;

    if (leftOver === 10 || leftOver === 11) leftOver = 0;

    if (leftOver !== parseInt(input.substring(9, 10))) return { valid: false, text: 'Verifique se digitou o CPF corretamente!' }

    sum = 0;

    for (let i = 0; i < 10; i++) sum = sum + parseInt(input.substring(i, i + 1)) * (11 - i);

    leftOver = (sum * 10) % 11;

    if (leftOver === 10 || leftOver === 11) leftOver = 0;

    if (leftOver !== parseInt(input.substring(10, 11))) return { valid: false, text: 'Verifique se digitou o CPF corretamente!' }
    else return { valid: true, text: '' }
}

export function passportValidation(input) {
    input = input.toLowerCase();

    if (!input) return { valid: false, text: 'Campo obrigatório!' };
    else {
        // const pattern = /^([a-z]){2}([0-9]){6}$/i

        // if (!pattern.test(input)) {
        //     console.log(input);
        //     return { valid: false, text: 'Verifique se digitou o nº do passaporte corretamente!' }
        // } else {
        //     console.log(input);
        //     return { valid: true, text: '' };
        // }

        return { valid: true, text: '' };
    }
}

export function emailValidation (input) {
    input = input.toLowerCase();
  
    if (input === "") return { valid: false, text: 'Campo obrigatório!' };
    else {
        if (pattern.test(input)) return { valid: true, text: '' };
        else {
            return { valid: false, text: 'Verifique se digitou o Email corretamente!' };
        };
    }
}

export function passwordValidation (input) {
    if (input === '') return { valid: false, text: 'Campo obrigatório!' };
    
    else if (!/^[0-9]/i.test(input)) return { valid: false, text: 'Senha inválida!' };
    
    else return { valid: true, text: '' };
}

export function passwordCreate (input) {
    if (input === '') return { valid: false, text: 'Campo obrigatório!' };
    
    else if (!/^[0-9]/i.test(input)) return { valid: false, text: 'Senha inválida!' };

    else if (input.length < 6 || input.length > 6) return { valid: false, text: 'Não pode ter menos ou mais que 6 digitos!' };
    
    else {
        password = input;
        return { valid: true, text: '' };
    }
}

export function confirmPasswordValidation (input) {
    if (input === '') return { valid: false, text: 'Campo obrigatório!' };

    else if (!/^[0-9]/i.test(input)) return { valid: false, text: 'Só pode conter números!' };

    else if (input.length < 6 || input.length > 8) return { valid: false, text: 'Não pode ter menos que 6 ou mais de 8 digitos!' };

    else if (password !== input) return { valid: false, text: 'Confira se a senha a confirmação estão corretas!' };

    else return { valid: true, text: '' };
}

export function nameValidations (input) {
    if (input === '') return { valid: false, text: 'Campo obrigatório!' };
    
    else if (input.length < 2) return { valid: false, text: 'O nome precisa no mínimo 2 caracteres!' };
    
    else return { valid: true, text: '' };
}

export function telValidation (input) {
  let numberTel = input.replace(/\D/g, '');
  if (numberTel === '') return { valid: false, text: 'Campo obrigatório!' };

  else if (numberTel.length !== 11 && numberTel.substr(2, 1) !== 9 ) return { valid: false, text: 'Use um número de celular para cadastro!' };
  
  else return { valid: true, text: '' };
}

export function telRecoverValidation (input) {
  let numberTel = input.replace(/\D/g, '');
  if (numberTel === '') return { valid: false, text: 'Campo obrigatório!' };

  else if (numberTel.length < 11 && numberTel.substr(2, 1) !== 9 ) return { valid: false, text: 'Use um número de celular para cadastro!' };
  
  else return { valid: true, text: '' };
}

export function codeValidation (input) {
    if (input === '') return { valid: false, text: 'Campo obrigatório!' };
    
    else if (input.length < 4) return { valid: false, text: 'Código inválido!' };
    
    else return { valid: true, text: '' };
}

export function checkedValidation (input) {
    if (!input) return { valid: false, text: 'Campo obrigatório!' };
    
    else return { valid: true, text: '' };
}

export function cepValidation (input) {
    let currentValue = input.replace(/\D/g, '');
    if (!input) return { valid: false, text: 'Campo obrigatório!' };

    else if (currentValue.length < 8) return { valid: false, text: 'Digite um CEP válido!' };

    else return { valid: true, text: '' };
}

export function streetValidation (input) {
    if (!input) return { valid: false, text: 'Campo obrigatório!' };

    else return { valid: true, text: '' };
}

export function street_numberValidation (input) {
    if (!input) return { valid: false, text: 'Campo obrigatório!' };

    else return { valid: true, text: '' };
}

export function neighborhoodValidation (input) {
    if (!input) return { valid: false, text: 'Campo obrigatório!' };

    else return { valid: true, text: '' };
}

export function cityValidation (input) {
    if (!input) return { valid: false, text: 'Campo obrigatório!' };

    else return { valid: true, text: '' };
}

export function stateValidation (input) {
    if (!input) return { valid: false, text: 'Campo obrigatório!' };

    else return { valid: true, text: '' };
}