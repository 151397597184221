import { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';

import {
    Backdrop,
    Box,
    Button,
    Fade,
    Grid,
    Modal,
    Typography
} from '@mui/material';

import { Close, ConfirmationNumber } from '@mui/icons-material';

import PrintVoucher from './PrintVoucher';
import layoutPage from '../../theme/pageThemes';

export default function ModalDownloadVoucher({ voucher }) {
    const theme = layoutPage();
    const componentRef = useRef();

    const [open, setOpen] = useState(false);
    
    function handleClose() { setOpen(!open) }

    return (
        <>
            <Button
                className={theme.btnSubmit}
                disabled={voucher?.status === 'Ativo' ? false : true}
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => setOpen(!open)}
                sx={{ alignItems: 'center', gap: 1 }}
            >
                <ConfirmationNumber />
                Visualizar
            </Button>
            
            <Box>
                <Modal
                    className={theme.modal}
                    closeAfterTransition
                    open={open}
                    onClose={handleClose}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade
                        in={open}
                        sx={{
                            position: 'relative',
                            padding: '16px 24px 32px',
                            backgroundColor: '#060606',
                            maxHeight: '90vh',
                            overflowY: 'auto',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <Box sx={{ p: 1 }}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 225,
                                    left: 0,
                                    
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 'min(46px, 15vw)',
                                        height: 'min(46px, 15vw)',
                                        borderRadius: '100% 100%',
                                        backgroundColor: '#060606'
                                    }}
                                />

                                <Box
                                    sx={{
                                        width: 'min(46px, 15vw)',
                                        height: 'min(46px, 15vw)',
                                        borderRadius: '100% 100%',
                                        backgroundColor: '#060606'
                                    }}
                                />
                            </Box>
                            
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                gap={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography variant='h3' color="secondary">Seu voucher</Typography>
                                    <Close
                                        onClick={handleClose}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <PrintVoucher ref={componentRef} voucher={voucher} />
                                </Grid>

                                <ReactToPrint
                                    content={() => componentRef.current}
                                    trigger={() => (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size="large"
                                        >
                                            Download
                                        </Button>
                                    )}
                                />
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </>
    )
}