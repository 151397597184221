import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceData } from 'react-device-detect';

import {
    Backdrop,
    Box,
    Button,
    Card,
    Fade,
    Grid,
    Modal,
    Typography
} from '@mui/material';

import { Close } from '@mui/icons-material';

// import { androidHref, appleHref } from '../utils/services/urls';
import { eventData } from '../utils/services/eventData';
import { description } from '../utils/fx/replaces';
import { sortDate, weekDay } from '../utils/fx/getDate';

import LoadingU from './LoadingU';

import getEventImage from '../utils/fx/getEventImage';
import layoutPage from '../theme/pageThemes';
import dataEvent from '../utils/fx/dataEvent';

const styleCards = {
    color: 'white',
    padding: '18px 8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#090909',
};

export default function ModalBuyEvent(props) {
    const { event, element, style, title } = props;
    const theme = layoutPage();
    const navigate = useNavigate();
    const deviceInfo = useDeviceData();
    const imgURL = getEventImage(event.pictures, 'banner');

    const [eventCurrent, setEventCurrent] = useState(event);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleOpen() { setOpen(true); }
    function handleClose() { setOpen(false); }

    async function openModal() {
        setLoading(true);
        const data = await eventData(event._id);
        
        if (data.dates.length > 1 && data.dates.length !== 0) {
            setEventCurrent(data);
            handleOpen();
            setLoading(false);
        } else {
            dataEvent(data?.dates[0]._id, data);
            navigate('/app/eventos/comprar-voucher');
        }
    }

    return (
        <Fragment>
            <LoadingU loading={loading} />
            <Button
                {...props}
                sx={style}
                onClick={() => {
                    // deviceInfo.os.name.toLowerCase() === 'ios'
                    //     ? window.location.href = appleHref
                    //     : deviceInfo.os.name.toLowerCase() === 'android'
                    //         ? window.location.href = androidHref
                    //         : openModal()

                    openModal();
                }}
            >
                {title}
            </Button>

            <Box>
                <Modal
                    className={theme.modal}
                    closeAfterTransition
                    open={open}
                    onClose={handleClose}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade
                        in={open}
                        sx={{
                            padding: "21px 16px",
                            backgroundColor: "#060606",
                            maxWidth: 570,
                            maxHeight: "90vh",
                            overflowY: "auto",
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <Box sx={{ padding: 1,  }}>
                            <Grid
                                container
                                display="flex"
                                flexDirection="column"
                                gap={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Close
                                        onClick={handleClose}
                                        sx={{ cursor: "pointer" }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    textAlign="center"
                                    sx={{ width: '100%' }}
                                >
                                    <Box
                                        component="img"
                                        src={imgURL}
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="flex-start"
                                >
                                    <Typography
                                        variant="h2"
                                        fontSize={26}
                                    >
                                        {eventCurrent.event_name}
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                >
                                    {
                                        !eventCurrent.dates || eventCurrent.dates.length < 1
                                            ?
                                                <Card sx={[styleCards, { justifyContent: 'flex-end' }]}>
                                                    <Button
                                                        className={!eventCurrent.setting.has_gratuity ? theme.btnCards : theme.btnSoon}
                                                        size="large"
                                                        variant="contained"
                                                        color={!eventCurrent.setting.has_gratuity ? "secondary" : "soon"}
                                                        onClick={() => {
                                                            dataEvent('', eventCurrent);
                                                            navigate('/app/eventos/comprar-voucher');
                                                        }}
                                                    >
                                                        {!eventCurrent.setting.has_gratuity ? 'comprar' : 'gratuito'}
                                                    </Button>
                                                </Card>
                                            :
                                                <>
                                                    <Typography
                                                        variant="h6"
                                                        color="secondary"
                                                    >
                                                        Datas disponíveis
                                                    </Typography>
                                                    {
                                                        eventCurrent.dates.sort((a, b) => sortDate(a, b)).map((date, i) => (
                                                            <Card
                                                                key={i}
                                                                sx={[
                                                                    styleCards,
                                                                    { justifyContent: 'space-between' }
                                                                ]}
                                                            >
                                                                <Box>
                                                                    <Typography variant="h6">
                                                                    {date.begin_date.substring(0, 10)}
                                                                    </Typography>
                                                                    <Typography variant="h6">
                                                                    {`${weekDay(date.begin_date.substring(0, 10))}`}
                                                                    </Typography>
                                                                </Box>
                                                                <Button
                                                                    className={!eventCurrent.setting.has_gratuity ? theme.btnCards : theme.btnSoon}
                                                                    size="large"
                                                                    variant="contained"
                                                                    color={!eventCurrent.setting.has_gratuity ? "secondary" : "soon"}
                                                                    onClick={() => {
                                                                        dataEvent(date._id, eventCurrent);
                                                                        navigate('/app/eventos/comprar-voucher');
                                                                    }}
                                                                >
                                                                    {!eventCurrent.setting.has_gratuity ? 'comprar' : 'gratuito'}
                                                                </Button>
                                                            </Card>
                                                        ))
                                                    }
                                                </>
                                    }
                                </Grid>

                                {
                                    eventCurrent.setting.has_sanitary_restriction &&
                                        <Grid item xs={12}>
                                            <Typography
                                                color="secondary"
                                                variant="h6"
                                                mb={1}
                                            >
                                                Restrição Sanitária
                                            </Typography>

                                            <Typography
                                                color="#fff"
                                                variant="h6"
                                                textAlign="justify"
                                                fontWeight={400}
                                            >
                                                {eventCurrent.sanitary_restriction}
                                            </Typography>
                                        </Grid>
                                }
                                
                                {
                                    (eventCurrent.event_description || eventCurrent.event_short_slogan) &&
                                        <Grid item xs={12}>
                                            <Typography
                                                color="secondary"
                                                variant="h6"
                                                mb={1}
                                            >
                                                Sobre o Evento
                                            </Typography>

                                            <Typography
                                                display="flex"
                                                flexDirection="column"
                                                color="#fff"
                                                variant="h6"
                                                textAlign="justify"
                                                fontWeight={400}
                                                gap={1}
                                            >
                                                {
                                                    description(event)
                                                        .map((item, i) => <span key={i}>{item}</span>)
                                                }
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    event.know_more.title &&
                                        <Grid item xs={12}>
                                            <Typography
                                                color="secondary"
                                                variant="h6"
                                                mb={1}
                                            >
                                                Saiba mais
                                            </Typography>
                                            <Typography
                                                color="#fff"
                                                variant="h6"
                                                textAlign="justify"
                                                fontWeight={400}
                                            >
                                                <a
                                                    href={`${event.know_more.link}`}
                                                    target="_blank"
                                                    style={{
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    {event.know_more.title}
                                                </a>
                                            </Typography>
                                        </Grid>
                                }

                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        color="#fff"
                                        variant="h6"
                                        textAlign="justify"
                                        fontWeight={400}
                                    >
                                        <span style={{ color: "#ccff00", fontWeight: 'bold' }}>Classificação:</span>
                                        {
                                            eventCurrent.indicative_age === 0
                                                ? 'Livre'
                                                : ` ${eventCurrent.indicative_age} anos`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </Fragment>
    )
}