import axios from 'axios';

const timeout = 5000;

export async function register(obj) {
    const fields = JSON.stringify({
        account_register_type: 'minimal',
        init_status: '2',

        user_info: {
            full_name: obj.name,
            preferred_name: obj.social_name !== '' ? obj.social_name : obj.name,
            email_address: obj.email,
            stored_password: obj.password,

            contact: {
                phone_number_1: {
                    ddd: obj.tel.replace(/\D/g, '').substring(0, 2),
                    phone_number: obj.tel.replace(/\D/g, '').substring(2, 11)
                }
            },

            document_info: {
                cpf: {
                    document_number: !obj.docSelect
                                        ? obj.cpf_passport.replace(/\D/g, '')
                                        : ''
                },

                passporte: {
                    document_number: obj.docSelect
                                        ? obj.cpf_passport
                                        : ''
                }
            },

            address: {
                state: obj.state.toUpperCase(),
                city: obj.city,
                street: obj.street,
                neighborhood: obj.neighborhood,
                street_number: obj.street_number,
                zip_code: obj.cep,
                complement: obj.complement,
                lat: '-',
                lng: '-',
                address_proof_picture: '-'
            }
        },

        partner_banking: {
            partner_name: 'URBANKY'
        }
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/banking/account`,
        method: 'post',
        timeout: timeout,
        data: fields,

        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            "Content-type": "application/json"
        }
    };

    return await axios(config)
        .then(() => {
            return {
                valid: true,
                text: ''
            };
        })
        .catch(({ response }) => {
            if (response.data.message.code === 'E00012') {
                return {
                    valid: false,
                    text: 'Usuário já cadastrado.'
                };
            } else {
                return {
                    valid: false,
                    text: 'Falha no cadastro. Tente novamente mais tarde.'
                };
            }
        });
}

export function getCEP(cep) {
    const config = {
        url: `https://brasilapi.com.br/api/cep/v2/${cep}`,
        method: 'get',
        timeout: timeout,
        headers: {}
    };

    return axios(config)
        .then(({ data }) => data)
        .catch(() => []);
}