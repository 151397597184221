import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { disableBtn, useErrors } from '../utils/hooks/useErrors';
import { Box, Button, Container, Grid, MenuItem, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { ArrowBack, ContentCopy, CreditCard, Pix } from '@mui/icons-material';
import { Formik } from 'formik';
import { SnackbarAlert, triggerAlert } from '../components/ShowAlerts';
import { CardName, CardNumber, CardValid } from '../components/payment/CardData';

import { login, userData } from '../utils/services/access';
import { checkedCupom, listCardsFavorite, payCard, saveCard } from '../utils/services/payments';
import { cardFlags, cardMask } from '../utils/fx/masks';
import {
    cardCVCValidation,
    cardExpiryValidation,
    cardNumberValidation,
    holderValidations,
    installmentValidation
} from '../utils/validations/buyValidations';

import Helmet from 'react-helmet';
import InputMask from 'react-input-mask';
import LoadingU from '../components/LoadingU';
import Footer from '../components/Footer';
import SlideCards from '../components/payment/SlideCards';

import layoutPage from '../theme/pageThemes';
import appMaintenance from '../utils/services/appMaintenance';
import QrCode from '../components/payment/QrCode';
import { LogoU } from '../components/Logos';

export default function Payment() {
    const theme = layoutPage();
    const navigate = useNavigate();
    const screens = useMediaQuery('@media(max-width: 500px)');
    
    const logged = login();
    const user = userData();
    const event = JSON.parse(sessionStorage.getItem('event'));
    const vouchers = JSON.parse(sessionStorage.getItem('vouchers'));
    
    const componentRef = useRef();
    const [scheme, setScheme] = useState('');
    const [discountValue, setDiscountValue] = useState(0);
    const [installments, setInstallments] = useState([]);
    const [myCards, setMyCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [qrcode, setQrcode] = useState(false);

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(
        createContext({
            cardCVC: cardCVCValidation,
            cardExpiry: cardExpiryValidation,
            cardNumber: cardNumberValidation,
            holderName: holderValidations,
            installment: installmentValidation
        })
    );

    const [errors, fieldValidation, toContinue] = useErrors(validations);

    function handleOpen(text, severity = 'error') {
        setOpenAlert({
            ...openAlert,
            open: true,
            severity: severity,
            text: text
        });
    }

    function handleClose() {
        setOpenAlert({
            ...openAlert,
            open: false,
            text: ''
        });
    }

    function handleBlur(e) {
        fieldValidation(e);
        triggerAlert(e, validations, handleOpen, handleClose);
    }

    async function handleDiscount(e, values) {
        setLoading(true);
        try {
            const app = await appMaintenance();
            const amount = vouchers.amount + vouchers.tickets.fee_convenience;
            const discount = await checkedCupom(vouchers.event_id, e.target.value, amount);

            if (app) {
                setLoading(false);
                navigate('/app/manutencao');

            } else {
                values.cupom = e.target.value;
                values.cupom_id = discount.id;

                setDiscountValue(vouchers.amount - discount.value);
                setLoading(false);
            }

            
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }
    
    function disabledBTN(values) {
        const newValues = {};
        for (let field in validations) {
            field === 'cardNumber'
                ? newValues[field] = values[field].replace('**** ****', '0000 0000')
                : newValues[field] = values[field]
        }
        
        return disableBtn(newValues, validations);
    }
    
    async function finishyPayment(values) {
        if (!values.useFavorite) {
            values.cardNumber = values.cardNumber.replace(/\D/g, '');
            values.cardFlag = cardFlags(values.cardNumber).toString().toUpperCase();

        } else values.cardFlag = scheme;


        if (toContinue()) {
            setLoading(true);
            
            try {
                const app = await appMaintenance();
                document.getElementById('cardCVC').value = '';
                
                if (!app) {
                    const finishyBuy = await payCard(user, vouchers, values);
                    values.cardCVC = '';
                    
                    if (finishyBuy.valid) {
                        if (values.cardSave) {
                            const save = await saveCard(user.id, values);
                            sessionStorage.setItem('proceed', true);
                            sessionStorage.setItem('msnConfirmation', JSON.stringify(finishyBuy));

                            handleOpen(save.text, save.severity);
                            setLoading(false);

                            setTimeout(() => {
                                handleClose();
                                navigate('/app/pagamento/confirmacao');
                            }, 4000);
                        } else {
                            sessionStorage.setItem('proceed', true);
                            sessionStorage.setItem('msnConfirmation', JSON.stringify(finishyBuy));
                            handleClose();
                            navigate('/app/pagamento/confirmacao');
                        }
                    } else {
                        handleOpen(finishyBuy.info);
                    }

                } else {
                    navigate('app/manutencao');
                }
            } catch {
                sessionStorage.setItem('error', true);
                throw navigate('/app/erro-inesperado');
                
            } finally { setLoading(false) }
        }
    }

    async function loadPage() {
        try {
            const parcels = [];
            const app = await appMaintenance();
            await listCardsFavorite(user.id);
            setLoading(false);
            
            if (app) {
                navigate('/app/manutencao');
                
            } else if (!vouchers || !logged) {
                window.history.back();

            } else {
                for (let i = 1; i <= event.installments; i++) {
                  let value = i;
                  let text = 'parcela de';
              
                  if (value > 1) text = 'parcelas de'
              
                  parcels.push({
                    value: value,
                    text: text
                  });
                }
                
                setDiscountValue(vouchers.amount);
                setMyCards(JSON.parse(sessionStorage.getItem('myCards')));
                setInstallments(parcels);
            }
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
        const teste = document.querySelector('#qrcode')
    },[]);

    useEffect(() => {
        if (refresh) {
            loadPage();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <>
            <Helmet>
                <title>Pagamento | Urbanky</title>
            </Helmet>
            <LoadingU loading={loading} />
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleClose}
            />
            {
                vouchers &&
                    <>
                        <Box className={theme.boxContainer} sx={{ backgroundImage: 'none !important' }}>
                            <Container maxWidth={!qrcode ? "md" : "sm"} className={theme.payContainer} >
                                <Typography
                                    color="textPrimary"
                                    variant="h2"
                                    mb={3}
                                >
                                    {!qrcode ? 'Informações do Cartão' : 'Pagamento com pix'}
                                </Typography>

                                {
                                    !qrcode
                                        ?
                                            <Formik
                                                initialValues={{
                                                    amount: vouchers.amount,
                                                    card_id: '',
                                                    cardFlag: '',
                                                    cardSave: false,
                                                    cardCVC: '',
                                                    cardExpiry: '',
                                                    cardNumber: '',
                                                    cupom: '',
                                                    cupom_id: '',
                                                    holderName: '',
                                                    installment: '',
                                                    maxCVC: 3,
                                                    useFavorite: false

                                                }}
                                                onSubmit={async(values) => await finishyPayment(values)}
                                            >
                                                {({ handleChange, handleSubmit, values }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        <Grid
                                                            container
                                                            spacing={3}
                                                            flexDirection="row-reverse"
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                                alignItems="top"
                                                                sx={{
                                                                    '@media (max-width: 1023px)': {
                                                                        display: 'flex',
                                                                        justifyContent: 'center'
                                                                    },

                                                                    '@media (max-width: 414px)': { display: 'block' }
                                                                }}
                                                            >
                                                                <Box sx={{ position: 'relative' }}>
                                                                    <Box
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: -59,
                                                                            right: 0,

                                                                            '@media (max-width: 1024px)': { position: 'static' }
                                                                        }}
                                                                    >
                                                                        <SlideCards
                                                                            handleChange={handleChange}
                                                                            handleClose={handleClose}
                                                                            favorite={myCards}
                                                                            setLoading={setLoading}
                                                                            setRefresh={setRefresh}
                                                                            setScheme={setScheme}
                                                                            values={values}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12} md={6}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ display: values.useFavorite ? 'none': 'block' }}>
                                                                            <InputMask
                                                                                mask={cardMask(values)}
                                                                                maskChar={null}
                                                                                value={values.cardNumber}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            >
                                                                                {
                                                                                    () =>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            error={!errors.cardNumber.valid}
                                                                                            id="cardNumber"
                                                                                            label="Número do Cartão"
                                                                                            name="cardNumber"
                                                                                            variant="standard"
                                                                                        />
                                                                                }
                                                                            </InputMask>
                                                                        </Box>

                                                                        <Box sx={{ display: !values.useFavorite ? 'none': 'block' }}>
                                                                            <CardNumber value={values.cardNumber} useFavorite={values.useFavorite} />
                                                                        </Box>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12}>
                                                                        <Box sx={{ display: values.useFavorite ? 'none': 'block' }}>
                                                                            <TextField
                                                                                fullWidth
                                                                                error={!errors.holderName.valid}
                                                                                id="holderName"
                                                                                label="Nome no Cartão"
                                                                                name="holderName"
                                                                                variant="standard"
                                                                                values={values.holderName}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            />
                                                                        </Box>

                                                                        <Box sx={{ display: !values.useFavorite ? 'none': 'block' }}>
                                                                            <CardName value={values.holderName} useFavorite={values.useFavorite} />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                                    
                                                            <Grid item xs={12}>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                >
                                                                    <Grid item xs={12} sm={6} md={3}>
                                                                        <TextField
                                                                            fullWidth
                                                                            error={!errors.cardCVC.valid}
                                                                            id="cardCVC"
                                                                            label="CVC"
                                                                            name="cardCVC"
                                                                            type="password"
                                                                            variant="standard"
                                                                            values={values.cardCVC}
                                                                            inputProps={{
                                                                                maxLength: values.maxCVC,
                                                                            }}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                        />
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12} sm={6} md={3}>
                                                                        <Box sx={{ display: values.useFavorite ? 'none': 'block' }}>
                                                                            <InputMask
                                                                                mask="99/99"
                                                                                maskChar={null}
                                                                                value={values.cardExpiry}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                            >
                                                                                {
                                                                                    () =>
                                                                                        <TextField
                                                                                            fullWidth
                                                                                            error={!errors.cardExpiry.valid}
                                                                                            id="cardExpiry"
                                                                                            label="Validade"
                                                                                            name="cardExpiry"
                                                                                            variant="standard"
                                                                                        />
                                                                                }
                                                                            </InputMask>
                                                                        </Box>

                                                                        <Box sx={{ display: !values.useFavorite ? 'none': 'block' }}>
                                                                            <CardValid value={values.cardExpiry} useFavorite={values.useFavorite} />
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid 
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        display='flex'
                                                                        justifyContent='flex-end'
                                                                        flexWrap="wrap"
                                                                        gap={3.25}
                                                                        mt={5.5}
                                                                        sx={{ '@media (max-width: 1024px)': { mt: 1.25 } }}
                                                                    >
                                                                        <TextField
                                                                            select
                                                                            className={theme.parcel}
                                                                            error={!errors.installment.valid}
                                                                            id="select"
                                                                            label="Pagar em quantas parcelas?"
                                                                            name="installment"
                                                                            variant="outlined"
                                                                            value={values.installment}
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>Pagar em quantas parcelas?</em>
                                                                            </MenuItem>
                                                                            {
                                                                                installments.map((parcel, i) => (
                                                                                    <MenuItem
                                                                                        key={i}
                                                                                        value={parcel.value}
                                                                                    >
                                                                                        {
                                                                                        `${parcel.value} ${parcel.text} ${(discountValue / parcel.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`
                                                                                        }
                                                                                    </MenuItem>
                                                                                ))
                                                                            }
                                                                        </TextField>

                                                                        <TextField
                                                                            className={theme.cupom}
                                                                            id="select"
                                                                            label="Cupom de desconto"
                                                                            name="cupom"
                                                                            variant="outlined"
                                                                            value={values.cupom}
                                                                            onBlur={(e) => handleDiscount(e, values)}
                                                                            onChange={handleChange}
                                                                            sx={{ display: vouchers.has_coupom ? 'block' : 'none' }}
                                                                        />
                                                                    </Grid>
                                                                    
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'flex-start',

                                                                            '@media (max-width: 280px)': { justifyContent: 'space-between' }
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            color={!values.useFavorite ?'#464646' : '#CCC'}
                                                                            fontStyle="normal"
                                                                            fontWeight="normal"
                                                                            fontSize={14}
                                                                            lineHeight="160%"
                                                                        >
                                                                            Salvar cartão
                                                                        </Typography>
                                                                        
                                                                        <Switch
                                                                            disabled={values.useFavorite}
                                                                            checked={values.cardSave}
                                                                            name="cardSave"
                                                                            color="primary"
                                                                            onChange={handleChange}
                                                                        />
                                                                    </Grid>
                                                                    
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={6}
                                                                        alignItems="end"
                                                                        sx={{
                                                                            mt: 2.25,
                                                                            display: 'flex',
                                                                            flexWrap: 'wrap-reverse',
                                                                            gap: 3,
                                                    
                                                                            '@media screen and (max-width: 280px)': { mt: 1.25 }
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            className={theme.btnSubmit}
                                                                            disabled={disabledBTN(values)}
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            type="submit"
                                                                        >
                                                                            Finalizar
                                                                        </Button>
                                                                        
                                                                        <Button
                                                                            className={theme.btnSubmit}
                                                                            color="ternary"
                                                                            variant="contained"
                                                                            startIcon={<ArrowBack />}
                                                                            onClick={() => window.history.back()}
                                                                        >
                                                                            Voltar
                                                                        </Button>
                                                                    </Grid>
                                                                    
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={6}
                                                                        alignItems="end"
                                                                    >
                                                                        <Box
                                                                            gap={2.25}
                                                                            display="flex"
                                                                            alignItems="flex-end"
                                                                            justifyContent="flex-end"
                                                                        >
                                                                            {/* <Button
                                                                                className={theme.btnSubmit}
                                                                                color="quaternary"
                                                                                variant="contained"
                                                                                startIcon={<Pix />}
                                                                                onClick={() => setQrcode(!qrcode)}
                                                                                sx={{ alignItems: 'flex-start' }}
                                                                            >
                                                                                Pagamento pix
                                                                            </Button> */}

                                                                            <Box>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    textAlign="right"
                                                                                >
                                                                                    Valor total
                                                                                </Typography>

                                                                                <Typography
                                                                                    variant="h2"
                                                                                    textAlign="right"
                                                                                    sx={{ '@media (max-width: 355px)': { fontSize: 23 } }}
                                                                                >
                                                                                    {(discountValue + vouchers.tickets.fee_convenience).toLocaleString("pt-BR", {
                                                                                        style: "currency",
                                                                                        currency: "BRL",
                                                                                    })}
                                                                                </Typography>

                                                                                {
                                                                                    vouchers.tickets.fee_convenience <= 0
                                                                                        ? null
                                                                                        :
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                textAlign="right"
                                                                                            >
                                                                                                {`taxa de conveniência: ${vouchers.tickets.fee_convenience.toLocaleString("pt-BR", {
                                                                                                    style: "currency",
                                                                                                    currency: "BRL",
                                                                                                })}`}
                                                                                            </Typography>
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                )}
                                            </Formik>

                                        : <QrCode size={264} setqrcode={setQrcode} value="https://urbanny.com.br" />

                                }
                            </Container>
                        </Box>
                        <Footer marginTop={1} />
                    </>
            }
        </>
    )
}