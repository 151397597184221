import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';

import Helmet from 'react-helmet';
import Footer from '../components/Footer';

import layoutPage from '../theme/pageThemes';
import { androidHref, appleHref } from '../utils/services/urls';

export default function UnexpectedError() {
    const theme = layoutPage();
    const navigate = useNavigate();
    const error = sessionStorage.getItem('error');

    useEffect(() => {
        sessionStorage.removeItem('event');
        sessionStorage.removeItem('vouchers');
        if (!error) navigate('/app/eventos');
    }, [error]);
    
    return (
        <>
            <Helmet>
                <title>Erro Inesperado | Urbanky</title>
            </Helmet>

            <Box className={theme.containerErrorPages}>
                <Container maxWidth="md" sx={{ backgroundColor: '#111' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <img
                            alt="Under development"
                            src="/img/UnexpectedError.svg"
                            style={{
                            display: 'inline-block',
                            maxWidth: '100%',
                            width: 360
                            }}
                        />
                    </Box>

                    <Typography
                        variant="h1"
                        color="secondary"
                        align="center"
                        mt={5}
                    >
                        Encontramos um problema!
                    </Typography>
                    
                    <Typography
                        variant="subtitle2"
                        color="text.ternary"
                        align="center"
                        fontSize="1.1em"
                        mt={2}
                    >
                        Tente novamente mais tarde ou entre em contato com o SAC.
                    </Typography>

                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent:'center',
                            gap: '10px'
                        }}
                    >
                        <Button
                            href={appleHref}
                            target="_blank"
                            sx={{ p: 0 }}
                        >
                            <img
                                height={50}
                                alt="Logo"
                                src="/img/apple.svg"
                            />
                        </Button>

                        <Button
                            href={androidHref}
                            target="_blank"
                            sx={{ p: 0 }}
                        >
                            <img
                                height={50}
                                alt="Logo"
                                src="/img/android.svg"
                            />
                        </Button>
                    </Box>

                </Container>
            </Box>

            <Footer />
        </>
    )
}