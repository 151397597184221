import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { routes } from './routes';
import { listEvents } from './utils/services/eventData';

import GlobalStyles from './components/GlobalStyles';
import BuySpecificEvent from './pages/BuySpecificEvent';

import theme from './theme';

import './css/App.css';
import './css/slide.css';
import './css/card-credit.css';

function App() {
    const [newRoutes, setNewRoutes] = useState([]);
    const content = useRoutes(newRoutes);

    async function loadAPP() {
        try {
            const events = await listEvents();
            const newListRoutes = routes;

            events.forEach(event => newListRoutes[1].children.push({
                path: `eventos/${event.abv_event.toLowerCase()}`,
                element: <BuySpecificEvent />
            }));

            // console.log(newListRoutes)
            
            setNewRoutes(newListRoutes);
        } catch {
            setNewRoutes(routes);
        }
    }

    useEffect(() => {
        loadAPP();
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {content}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
