export function boardingValidation(input) {
    if (input === '') return { valid: false, text: 'Escolha um ponto de embarque' }
    else return { valid: true, text: '' }
}

export function hourValidation(input) {
    if (input === '') return { valid: false, text: 'Escolha um horário para o embarque' }
    else return { valid: true, text: '' }
}

export function cardCVCValidation(input) {
    if (input === '') return { valid: false, text: 'Campo Obrigatório!' }
    else if (input.length < 3) return { valid: false, text: 'Verifique se digitou corretamente!' }
    else return { valid: true, text: '' }
}

export function cardExpiryValidation(input) {
    let currentValue = input.replace(/\D/g, '');

    if (currentValue === '') return { valid: false, text: 'Campo Obrigatório!' }
    else if (currentValue.length < 4) return { valid: false, text: 'Verifique a data de valide do cartão!' }
    else return { valid: true, text: '' }
}

export function cardNumberValidation(input) {
    let currentValue = input.replace(/\D/g, '');

    if (currentValue === '') return { valid: false, text: 'Campo Obrigatório!' }
    else if (currentValue.length < 14) return { valid: false, text: 'Verifique o número do cartão!' }
    else return { valid: true, text: '' }
}

export function holderValidations (input) {
    if (input === '') return { valid: false, text: 'Campo obrigatório!' };
    
    else if (input.length < 2) return { valid: false, text: 'O nome precisa no mínimo 2 caracteres!' };
    
    else return { valid: true, text: '' };
}

export function installmentValidation(input) {
    let currentValue = input;

    if (currentValue === '') return { valid: false, text: 'Campo Obrigatório!' }
    else return { valid: true, text: '' }
}