import { createContext, useContext, useRef, useState } from 'react';
import { useErrors } from '../../utils/hooks/useErrors';

import { Box, Grid, TextField, Typography } from '@mui/material';
import { Password } from '@mui/icons-material';
import { SnackbarAlert, triggerAlert } from '../ShowAlerts';

import { confirmPasswordValidation, passwordCreate } from '../../utils/validations/userValidations';

import InputMask from 'react-input-mask';

export default function NewPassword({ handleChange, setValidations, values }) {
    const componentRef = useRef();

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(createContext({
        newPassword: passwordCreate,
        confirmPassword: confirmPasswordValidation
    }));

    const [errors, fieldsValidations] = useErrors(validations);

    function handleOpenAlert(text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            text: text
        });
    }

    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false
        });
    }

    function handleBlur(e) {
        setValidations(validations);
        fieldsValidations(e);
        triggerAlert(e, validations, handleOpenAlert, handleCloseAlert);
    }

    return (
        <>
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Password sx={{ color: "#666", fontSize: 22, mr: 1 }} />
                        <Typography color="#666">Senha</Typography>
                    </Box>
                                        
                    <InputMask
                        mask="999999"
                        maskChar={null}
                        value={values.newPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        {() =>
                            <TextField
                                fullWidth
                                error={!errors.newPassword.valid}
                                type="password"
                                name="newPassword"
                                variant="standard"
                                placeholder="Senha"
                                sx={{ m: 0 }}
                            />
                        }
                    </InputMask>
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex">
                        <Password sx={{ color: "#666", fontSize: 22, mr: 1 }} />
                        <Typography color="#666">Confirmar senha</Typography>
                    </Box>
                                        
                    <InputMask
                        mask="999999"
                        maskChar={null}
                        value={values.confirmPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        {() =>
                            <TextField
                                fullWidth
                                error={!errors.confirmPassword.valid}
                                type="password"
                                name="confirmPassword"
                                variant="standard"
                                placeholder="Confirmar senha"
                                sx={{ m: 0 }}
                            />
                        }
                    </InputMask>
                </Grid>
            </Grid>
        </>
    );
}