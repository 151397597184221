import { useEffect } from 'react';
import { Box, Container, Link, Typography } from '@mui/material';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { AppleStore, PlayStore } from './Logos';

import layoutPage from '../theme/pageThemes';
import { urbanky } from '../utils/services/urls';

export default function Footer({ marginTop }) {
    const theme = layoutPage();

    useEffect(() => {
        const e = document.querySelectorAll('.tm-current-year');
        e.forEach(item => item.textContent = new Date().getFullYear());
    }, []);

    return (
        <Container
            className={theme.footerContainer}
            component="section"
            maxWidth="xl"
            sx={{
                m: `${marginTop || 128}px 0 0`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={{
                    p: 'min(48px, 10vw)',
                    gap: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: 1200,
                    backgroundColor: '#00000093',
                    justifyContent: 'space-between'
                }}
            >
                <Box
                    sx={{
                        gap: 4,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        <Typography variant="h4" color="whitesmoke">
                            BAIXE NOSSO APP
                        </Typography>

                        <Box
                            sx={{ gap: 1, maxWidth: 230, display: 'flex', flexWrap: 'wrap' }}
                        >
                            <AppleStore className={theme.boxStore} />
                            <PlayStore className={theme.boxStore} />
                        </Box>
                    </Box>

                    <Box>
                        <Typography
                            variant="h4"
                            color="whitesmoke"
                            sx={{
                                '@media (max-width: 922px)': { textAlign: 'center' },
                                '@media (max-width: 747px)': { textAlign: 'right' },
                                '@media (max-width: 526px)': { textAlign: 'left' }
                            }}
                        >
                            SIGA
                        </Typography>

                        <Box sx={{ gap: 1, maxWidth: 230, display: 'flex', mt: .725 }}>
                            <Link href="https://www.facebook.com/urbanky.br" target="_blank">
                                <Facebook sx={{ color: '#fc0065', fontSize: 40 }} />
                            </Link>
                            
                            <Link href="https://twitter.com/urbanky_com_vc" target="_blank">
                                <Twitter sx={{ color: '#fc0065', fontSize: 40 }} />
                            </Link>

                            <Link href="https://www.instagram.com/urbanky.com.vc" target="_blank">
                                <Instagram sx={{ color: '#fc0065', fontSize: 40 }} />
                            </Link>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography
                            className={theme.textsFooter}
                            variant="h4"
                        >
                            PERGUNTAS FREQUENTES
                        </Typography>
                        
                        <Typography
                            className={theme.textsFooter}
                            variant="caption"
                        >
                            <Link
                                href={`${urbanky}/faq.html`}
                                target="_blank"
                                underline="hover"
                                color="whitesmoke"
                            >
                                    Qual é a sua dúvida?
                            </Link>
                        </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="h4" color="whitesmoke">
                            FALE CONOSCO
                        </Typography>

                        <Typography
                            variant="caption"
                            color="whitesmoke"
                            sx={{
                                '@media (max-width: 747px)': { textAlign: 'right' },
                                '@media (max-width: 526px)': { textAlign: 'left' },
                            }}
                        >
                            <Link
                                href={`${urbanky}/contato.html`}
                                target="_blank"
                                underline="hover"
                                color="whitesmoke"
                            >
                                    Entre em contato
                            </Link>
                        </Typography>

                        <Typography
                            variant="caption"
                            color="whitesmoke"
                            sx={{
                                '@media (max-width: 747px)': { textAlign: 'right' },
                                '@media (max-width: 526px)': { textAlign: 'left' },
                            }}
                        >
                            sac@urbanky.com.br
                        </Typography>
                    </Box>

                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography
                            className={theme.textsFooter}
                            variant="h4"
                        >
                            TERMOS DE USO
                        </Typography>

                        <Typography
                            variant="caption"
                                className={theme.textsFooter}
                            color="whitesmoke"
                        >
                            © URBANKY <span className="tm-current-year">2021</span> │ {' '}
                            <Link
                                href={`${urbanky}/termos-e-condicoes.html`}
                                target="_blank"
                                underline="hover"
                                color="whitesmoke"
                            >
                                Termos & Condições Urbanky
                            </Link>
                        </Typography>
                    
                        <Typography
                            className={theme.textsFooter}
                            variant="caption"
                        >
                            ©Urbanky®, All Rights Reserved <span className="tm-current-year">2020</span><br />
                            Todos os direitos reservados
                        </Typography>
                    </Box>
                </Box>

                <Typography
                    sx={{
                        fontSize: 9,
                        color: '#777',
                        textAlign: 'justify'
                    }}
                >
                    O URBANKY (URBANKY SOLUTIONS S.A. - CNPJ 43.890.374/0001-32 – situado na Av. Franklin Roosevelt, nº 115 - 4° Andar • Centro • Rio de Janeiro • RJ • CEP 20.021-120) uma Funtech, que atua como uma instituição de pagamentos que disponibiliza por meio de sua plataforma digital proprietária produtos e serviços financeiros de instituições devidamente autorizadas. Atua em parceria com o ATLANTIC BANK S.A - CNPJ 40.928.448./0001-94 / que é correspondente da casa do crédito CASA DO CRÉDITO – CNPJ – 05.442.029/0001-47, para custódia, depósito e liquidação de recursos de terceiros, e como correspondente bancário seguindo as diretrizes da Resolução nº 3.954 do Banco Central do Brasil, assim como o Urbanky atua em conjunto com a ATLANTIC PAY S/A. CNPJ: 40.788.964/0001-60 e a ATLANTIC CAPITAL S/A. CNPJ: 40.788.097/0001-63 e ATLANTIC PARKING LTDA – CNPJ: 44.038.051/0001-88, ATLANTIC INSURANCE S/A. CNPJ: 38.179.407/0001-09  como seu correspondente autorizado. Urbanky, Powered by © ATLANTIC FUTURE INC, CA.EUA.
                </Typography>
            </Box>
        </Container>
    )
}