import { useEffect } from 'react';
import { ContentCopy, CreditCard } from '@mui/icons-material';
import { Box, Button, TextField } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import layoutPage from '../../theme/pageThemes';
import { LogoU } from '../Logos';

export default function QrCode({ size, setqrcode, value }) {
    const theme = layoutPage();
    
    useEffect(() => {
        document.querySelector('#code path:last-child').style.fill = '#444';
    }, []);

    return (
        <Box
            display="flex"
            alignItems="center"
            gap="min(60px, 13vw)"
            flexDirection="column"
            justifyContent="space-between"
        >
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
            >
                <QRCodeSVG
                    id="code"
                    size={size}
                    value={value}
                />
                <Box
                    sx={{
                        position:'absolute',
                        mixBlendMode: 'multiply'
                    }}
                >
                    <LogoU height={240} />
                </Box>
            </Box>

            <Button
                className={theme.btnSubmit}
                color="secondary"
                variant="contained"
                startIcon={<ContentCopy />}
                onClick={() => {
                    const copyText = document.getElementById("qrcode");
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    document.execCommand('copy');
                }}
                sx={{ width: '100%' }}
            >
                Copiar código
            </Button>

            <TextField
                fullWidth
                id="qrcode"
                type="text"
                variant="outlined"
                value="https://urbanky.com.br"
                sx={{ '& input': { textAlign: 'center' }}}
            />

            <Button
                className={theme.btnSubmit}
                color="ternary"
                variant="contained"
                startIcon={<CreditCard />}
                onClick={() => setqrcode(false)}
                sx={{ width: '100%' }}
            >
                Pagar com cartão
            </Button>
        </Box>
    );
}