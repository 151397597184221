import { MenuItem, TextField } from '@mui/material';

export default function BoardingTrips(props) {
    const {
        amount,
        boarding,
        errors,
        handleBlur,
        handleChange,
        theme,
        values
    } = props;

    return (
        <TextField
            fullWidth
            select
            id="select"
            color="textField"
            className={theme.boarding}
            disabled={amount < 1 || values.boarding_point_id === '' ? true : false}
            error={!errors.trip_id.valid}
            name="trip_id"
            variant="outlined"
            value={values.trip_id}
            placeholder="selecione um horário de embarque"
            label="selecione um horário de embarque"
            onBlur={handleBlur}
            onChange={handleChange}
        >
            {
                boarding
                    .filter(item => item._id === values.boarding_point_id)
                    .map(times =>
                        times.trips.map(time => (
                            <MenuItem
                                key={time._id}
                                value={time._id}
                            >
                                {time.departure_time}
                            </MenuItem>
                        )
                    ))
            }
        </TextField>
    );
}