import { useState } from "react";

const createInitialState = (validations) => {
    const initialState = {};

    for (let field in validations) {
        initialState[field] = { valid: true, text: "" };
    }

    return initialState;
};

export const useErrors = (validations) => {
    const [errors, setErrors] = useState(createInitialState(validations));

    function fieldsValidation(event) {
        const { name, value } = event.target;
        const newState = { ...errors };
        newState[name] = validations[name](value);
        setErrors(newState);
    }

    const toContinue = () => {
        for (let field in errors) {
            if (!errors[field].valid) {
                return false;
            }
        }

        return true;
    };

    return [errors, fieldsValidation, toContinue];
};

export const disableBtn = (values, validations) => {
    const newFields = [];
    const newValues = {};
    const nameValidations = [];

    for (let field in values) newFields.push(field);

    for (let field in validations) nameValidations.push(field);
                        
    newFields.forEach(field => {
        nameValidations.forEach(validation => {
            if (field === validation) newValues[field] = validations[field](values[field]);
        });
    });

    for (let field in newValues) {
        if (!newValues[field].valid) {
            return true;
        }
    }

    return false;
};
