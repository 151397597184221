import { Autocomplete, TextField } from "@mui/material";

const FillField = ({ label, useFavorite, value }) => {
    const options = [value];

    return (
        <Autocomplete
            freeSolo
            disabled={useFavorite}
            value={value}
            options={options}
            renderInput={
                (params) => 
                    <TextField
                        {...params}
                        fullWidth
                        label={label}
                        variant="standard"
                    />
            }
        />
    )
};

export const CardNumber = ({ value, useFavorite }) => (
    <FillField
        label="Número do Cartão"
        value={value}
        useFavorite={useFavorite}
    />
);

export const CardName = ({ value, useFavorite }) => (
    <FillField
        label="Nome no Cartão"
        value={value}
        useFavorite={useFavorite}
    />
);

export const CardValid = ({ value, useFavorite }) => (
    <FillField
        label="Validade"
        value={value}
        useFavorite={useFavorite}
    />
);