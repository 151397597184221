import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceData } from 'react-device-detect';
import { Box, Button, Card, Container, Grid, Typography } from '@mui/material';

// import { androidHref, appleHref } from '../utils/services/urls';
import { eventData, listEvents } from '../utils/services/eventData';
import { weekDay } from '../utils/fx/getDate';

import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import LoadingU from '../components/LoadingU';

import appMaintenance from '../utils/services/appMaintenance';
import getEventImage from '../utils/fx/getEventImage';
import dataEvent from '../utils/fx/dataEvent';
import layoutPage from '../theme/pageThemes';
import { description } from '../utils/fx/replaces';

const styleCards = {
    color: 'white',
    padding: '18px 8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#090909',
};

export default function BuySpecificEvent() {
    const pathname = window.location.pathname;
    const theme = layoutPage();
    const navigate = useNavigate();
    const deviceInfo = useDeviceData();

    const [event, setEvent] = useState(false);
    const [imgURL, setImgURL] = useState(false);
    const [loading, setLoading] = useState(true);

    const banner = '/img/banners/city.png';

    async function loadPage() {
        try {
            const app = await appMaintenance();
            setLoading(false);

            if (app) navigate('/app/manutencao');
                
            // else if (deviceInfo.os.name.toLowerCase() === 'ios') window.location.replace(appleHref);
            // else if (deviceInfo.os.name.toLowerCase() === 'android') window.location.replace(androidHref);

            else {
                const abvs = pathname.split('/');
                const events = await listEvents();
                let event_id;
                let event;

                events.forEach(item => {
                    abvs.forEach(abv => {
                        if (abv === item.abv_event.toLowerCase()) event_id = item._id;
                    });
                });

                event = await eventData(event_id);
                setEvent(event);
                setImgURL(getEventImage(event.pictures, 'detail') || getEventImage(event.pictures, 'banner'));
            }

        } catch {
            setLoading(false);
            sessionStorage.getItem('error', true);
            navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Eventos | Urbanky</title>
            </Helmet>
            <LoadingU loading={loading} />
            
            {
                event &&
                    <>
                        <Box
                            sx={{
                                pt: 8,
                                width: '100%',
                                height: '45vh',
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundImage: `url(${banner})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',

                                '@media (max-width: 599px)': {
                                    height: '30vh',
                                    backgroundPosition: 'center'
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: 'calc(45vh * 2)',
                                    backgroundImage: `url(${imgURL})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                        </Box>

                        <Container maxWidth="sm" sx={{ m: '80px auto', backgroundColor: '#111' }}>
                            <Grid
                                container
                                // display="flex"
                                flexDirection="column"
                                gap={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="flex-start"
                                >
                                    <Typography
                                        variant="h2"
                                        color="text.ternary"
                                        fontSize={26}
                                    >
                                        {event.event_name}
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    flexDirection="column"
                                    gap={1}
                                >
                                    {
                                        !event.dates || event.dates.length < 1
                                            ?
                                                <Card sx={[styleCards, { justifyContent: 'flex-end' }]}>
                                                    <Button
                                                        className={theme.btnCards}
                                                        color="secondary"
                                                        size="large"
                                                        variant="contained"
                                                        onClick={() => {
                                                            dataEvent('', event);
                                                            navigate('/app/eventos/comprar-voucher');
                                                        }}
                                                    >
                                                        comprar
                                                    </Button>
                                                </Card>
                                            :
                                                <>
                                                    <Typography
                                                        variant="h6"
                                                        color="secondary"
                                                    >
                                                        Datas disponíveis
                                                    </Typography>
                                                    {
                                                        event.dates.map((date, i) => (
                                                            <Card
                                                                key={i}
                                                                sx={[
                                                                    styleCards,
                                                                    { justifyContent: 'space-between' }
                                                                ]}
                                                            >
                                                                <Box>
                                                                    <Typography variant="h6">
                                                                    {date.begin_date.substring(0, 10)}
                                                                    </Typography>
                                                                    <Typography variant="h6">
                                                                    {`${weekDay(date.begin_date.substring(0, 10))}`}
                                                                    </Typography>
                                                                </Box>
                                                                <Button
                                                                    className={theme.btnCards}
                                                                    color="secondary"
                                                                    size="large"
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        dataEvent(date._id, event);
                                                                        navigate('/app/eventos/comprar-voucher');
                                                                    }}
                                                                >
                                                                    comprar
                                                                </Button>
                                                            </Card>
                                                        ))
                                                    }
                                                </>
                                    }
                                </Grid>
                                
                                {
                                    (event.event_description || event.event_short_slogan) &&
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                        >
                                            <Typography
                                                color="secondary"
                                                variant="h6"
                                            >
                                                Sobre o Evento
                                            </Typography>

                                            <Typography
                                                display="flex"
                                                flexDirection="column"
                                                color="#fff"
                                                variant="h6"
                                                textAlign="justify"
                                                fontWeight={400}
                                                gap={1}
                                            >
                                                {
                                                    description(event)
                                                        .map((item, i) => <span key={i}>{item}</span>)
                                                }
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    event.setting.has_sanitary_restriction &&
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                        >
                                            <Typography
                                                color="secondary"
                                                variant="h6"
                                            >
                                                Restrição Sanitária
                                            </Typography>

                                            <Typography
                                                color="#fff"
                                                variant="h6"
                                                textAlign="justify"
                                                fontWeight={400}
                                            >
                                                {event.sanitary_restriction}
                                            </Typography>
                                        </Grid>
                                }

                                {
                                    event.know_more.title &&
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                        >
                                            <Typography
                                                color="secondary"
                                                variant="h6"
                                            >
                                                Saiba mais
                                            </Typography>

                                            <Typography
                                                color="#fff"
                                                variant="h6"
                                                textAlign="justify"
                                                fontWeight={400}
                                            >
                                                <a
                                                    href={`${event.know_more.link}`}
                                                    target="_blank"
                                                    style={{
                                                        color: '#fff',
                                                        cursor: 'pointer',
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    {event.know_more.title}
                                                </a>
                                            </Typography>
                                        </Grid>
                                }

                                <Grid item xs={12}>
                                    <Typography
                                        color="#fff"
                                        variant="h6"
                                        textAlign="justify"
                                        fontWeight={400}
                                    >
                                        <span style={{ color: "#ccff00", fontWeight: 'bold' }}>Classificação:</span>
                                        {
                                            event.indicative_age === 0
                                                ? 'Livre'
                                                : ` ${event.indicative_age} anos`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                        <Footer />
                    </>
            }
        </>
    );
}