import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';

import MainNavbar from './MainNavbar';
import MainSidebar from './MainSidebar';
import { login } from '../utils/services/access';

const MainLayoutRoot = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
}));

const MainLayoutWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
}));

const MainLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
});

const MainLayoutContent = styled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
});

export default function MainLayout() {
    const [logged, setLogged] = useState(login());
    const [isMobileNavOpen, setMobileNavOpen] = useState(true);

    return (
        <MainLayoutRoot>
            <MainNavbar logged={logged} onMobileNavOpen={() => setMobileNavOpen(true)} />
            <MainSidebar
                logged={logged}
                onLogged={setLogged}
                openMobile={isMobileNavOpen}
                onMobileClose={() => setMobileNavOpen(false)}
            />
            <MainLayoutWrapper>
                <MainLayoutContainer>
                    <MainLayoutContent>
                        <Outlet />
                    </MainLayoutContent>
                </MainLayoutContainer>
            </MainLayoutWrapper>
        </MainLayoutRoot>
    );
}