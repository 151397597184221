import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Backdrop, Button, Container, Fade, Grid, IconButton, Modal, Typography } from '@mui/material';
import { Close, DeleteForeverRounded } from '@mui/icons-material';
import { SnackbarAlert } from '../ShowAlerts';

import { userData } from '../../utils/services/access';
import { deleteCard } from '../../utils/services/payments';

import layoutPage from '../../theme/pageThemes';
import appMaintenance from '../../utils/services/appMaintenance';

export default function ModalDeleteCard({ card, setLoading, setRefresh }) {
    const theme = layoutPage();
    const user = userData();
    const navigate = useNavigate();
    const componentRef = useRef();

    const [modalOpen, setModalOpen] = useState(false);

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'warning',
        text: ''
    });

    function handleOpenModal() { setModalOpen(true) }
    function handleCloseModal() { setModalOpen(false) }

    function handleOpenAlert(severity, text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            severity: severity,
            text: text
        });
    }

    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false,
            text: ''
        });
    }

    async function removeCard() {
        setLoading(true);
        try {
            const app = await appMaintenance();

            if (app) {
                setLoading(false);
                navigate('/app/manutencao');

            } else {
                const delCard = await deleteCard(user.id, card._id);
        
                if (delCard.open) {
                    setRefresh(true);
                    handleOpenAlert(delCard.severity, delCard.text);
                    setLoading(false);
        
                    handleCloseModal();
                } else {
                    setLoading(false);
                    handleOpenAlert(delCard.severity, delCard.text);
                }
            }
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    return (
        <>
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />
            
            <IconButton
                onClick={handleOpenModal}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: '#fefefe99',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
            >
                <DeleteForeverRounded />
            </IconButton>

            <Modal
                className={theme.modal}
                closeAfterTransition
                open={modalOpen}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade
                    in={modalOpen}
                    sx={{
                        p: 2,
                        maxWidth: 324,
                        borderRadius: 4,
                        position: 'relative',
                        backgroundColor: "#fefefe",
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                >
                    <Container className={theme.modalContainer}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography
                                color="textPrimary"
                                variant="h2"
                                textAlign="left"
                                >
                                    Deletar cartão
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={2}
                                display="flex"
                                justifyContent="flex-end"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 20
                                }}
                            >
                                <IconButton onClick={handleCloseModal}>
                                    <Close color="primary"/>
                                </IconButton>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    color="primary"
                                    textAlign="center"
                                    mt={2}
                                    sx={{                
                                        '@media (max-width: 300px)': {
                                        fontSize: 14
                                        }
                                    }}
                                >
                                    Deseja apagar o cartão de nº:
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    textAlign="center"
                                    fontSize={20}
                                    color="primary"
                                >
                                    {`${card.pan_obscure}`}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="space-around">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={removeCard}
                                >
                                    sim
                                </Button>
                                <Button
                                    variant="contained"
                                    color="ternary"
                                    onClick={handleCloseModal}
                                >
                                    não
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Fade>
            </Modal>
        </>
    )
}