import { forwardRef } from 'react';

import {
    Avatar,
    Box,
    Grid,
    Typography
} from '@mui/material';

import {
    AccessTime,
    InsertInvitation,
    LocationOn
} from '@mui/icons-material';

import { QRCodeSVG } from 'qrcode.react';
import { weekDay } from '../../utils/fx/getDate';

import layoutPage from '../../theme/pageThemes';

const PrintVoucher = forwardRef(({ voucher }, ref) => {
    const theme = layoutPage();

    return (
        <Box
            ref={ref}
            sx={{
                p: '32px',
                m: '0 auto',
                display: "flex",
                alignItems: "center",
                color: '#333',
                maxWidth: 400,
                borderRadius: 4,
                backgroundColor: '#FFF !important'
            }}
        >
            <Grid
                container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2
                }}
            >
                <Grid item xs={12}>
                    <Avatar src={voucher?.ticket?.picture} sx={{ height: 120, width: 120 }} />
                </Grid>
                
                <Grid item xs={12}>
                    <Typography
                        variant="h2"
                        color="#333"
                        fontSize="min(24px, 8vw)"
                        mb={1}
                    >
                        {voucher.event.event_name}
                    </Typography>
                </Grid>
                
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                >
                    <Typography className={theme.boxInfo} variant="h6">
                        {voucher.transport.boarding_name}
                    </Typography>
                </Grid>
                
                <Grid
                    item
                    width="100%"
                    xs={12}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    gap={2}
                >
                    <Box>
                        <Typography>Data</Typography>
                        <Box className={theme.boxInfo}>
                            <InsertInvitation />
                            <Typography variant="h6">
                                {`${voucher.date.begin_date.substring(0, 10)} - ${weekDay(voucher.date.begin_date.substring(0, 10))}`}
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box>
                        <Typography>Horário</Typography>
                        <Box className={theme.boxInfo}>
                            <AccessTime />
                            <Typography variant="h6">
                                {voucher.transport.trip.departure_time}
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box>
                        <Typography>Local</Typography>
                        <Box className={theme.boxInfo} sx={{ alignItems: 'flex-start !important' }}>
                            <LocationOn />
                            <Typography variant="h6">
                                {`
                                    ${voucher.event.event_address.street},
                                    ${voucher.event.event_address.street_number}
                                    - 
                                    ${voucher.event.event_address.state}
                                `}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    gap={1}
                    mt={2}
                >
                    <Box
                        className={theme.boxInfo}
                        sx={{
                            padding: '12px !important',
                            borderRadius: '8px !important',
                            boxShadow: '0px 8px 16px #00000029',
                            backgroundColor: '#FEFEFE !important'
                        }}
                    >
                        <QRCodeSVG
                            size={180}
                            value={voucher.voucher_token_data}
                        />
                    </Box>
                </Grid>
                
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    gap={1}
                    mt={2}
                >
                    <Typography className={theme.boxInfo} variant="h6">
                        {voucher.order_token}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
});

export default PrintVoucher;