import axios from "axios";

export async function listVoucher(uid) {
    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/view/user/${uid}/voucher`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    };

    return await axios(config).then(({ data }) => data.data);
}