import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useErrors } from '../utils/hooks/useErrors';
import { useNavigate } from 'react-router-dom';

import {
    Autocomplete,
    Box,
    Button,
    // Checkbox,
    Container,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Switch,
    // Link,
    TextField,
    Typography
} from '@mui/material';

import {
    Apartment,
    ArrowBack,
    Money,
    Signpost,
    Email,
    FeaturedPlayList,
    LocalOffer,
    Password,
    Person,
    PhoneAndroid
} from '@mui/icons-material';

import { SnackbarAlert, triggerAlert } from '../components/ShowAlerts';
import { Formik } from 'formik';

import { login, loginValidation } from '../utils/services/access';
import { validateCodeCel } from '../utils/services/codesValidations';
// import { cepMask, telMask } from '../utils/fx/masks';
import { countries } from '../utils/__mocks__/countries';

import {
    cepValidation,
    cityValidation,
    // checkedValidation,
    confirmPasswordValidation,
    docValidation,
    emailValidation,
    nameValidations,
    neighborhoodValidation,
    passwordCreate,
    stateValidation,
    streetValidation,
    telValidation
} from '../utils/validations/userValidations';

import Helmet from 'react-helmet';
import InputMask from 'react-input-mask';
import LoadingU from '../components/LoadingU';
import ModalConfirmartion from '../components/register/ModalConfirmation';

import appMaintenance from '../utils/services/appMaintenance';
import layoutPage from '../theme/pageThemes';
import { getCEP, register } from '../utils/services/register';

export default function Register() {
    const theme = layoutPage();
    const componentRef = useRef();
    const navigate = useNavigate();

    const [barter, setBarter] = useState(false);
    const [loading, setLoading] = useState(true);
    const [acronym, setAcronym] = useState(countries[29]);

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(createContext({
        cpf_passport: docValidation,
        email: emailValidation,
        name: nameValidations,
        password: passwordCreate,
        confirmPassword: confirmPasswordValidation,
        tel: telValidation,
        cep: cepValidation,
        street: streetValidation,
        neighborhood: neighborhoodValidation,
        city: cityValidation,
        state: stateValidation,
        // terms: checkedValidation
    }));

    const [errors, fieldsValidations, toContinue] = useErrors(validations);

    function handleOpenAlert(text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            text: text
        });
    }

    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false
        });
    }

    function handleBlur(e) {
        fieldsValidations(e);
        triggerAlert(e, validations, handleOpenAlert, handleCloseAlert);
    }

    async function handleCEP(e, values, handleChange) {
        if (e.target.value.length > 7) {
            const getAddress = await getCEP(e.target.value.replace(/\D/g, ''));

            for (let field in getAddress) {
                const doc = document.getElementById(field);

                if (doc) {
                    values[field] = getAddress[field];
                    doc.value = getAddress[field];
                    
                    handleChange({ target: { name: field, value: values[field] } });
                }
            }
        }
    }

    async function sendRegister(values) {
        try {
            const app = await appMaintenance();

            if (app) {
                setLoading(false);
                navigate('/app/manutencao');

            } else {
                const sendCadastre = await register(values);
                values.code = '';
        
                if (!sendCadastre.valid) {
                    setLoading(false);
                    handleOpenAlert(sendCadastre.text);
        
                } else {
                    await loginValidation(values.email, values.password);
                    const isLogged = login();
                    setLoading(false);
        
                    if (!isLogged) handleOpenAlert('Usuário cadastrado com sucesso, mas não foi possível logar, tente logar novamente mais tarde!');
                    else {
                        const vouchers = JSON.parse(sessionStorage.getItem('vouchers'));
                                
                        vouchers
                            ? navigate('/app/pagamento')
                            : navigate('/app/eventos')
                    }
                }
            }
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    async function checkCode(values) {
        const validCode = await validateCodeCel(values);

        if (validCode) await sendRegister(values);
        else if (!validCode) {
            values.code= '';
            setLoading(false);
            handleOpenAlert('O código informado está incorreto.');
        }
    }

    async function loadPage() {
        try {
            const app = await appMaintenance();
            setLoading(false);

            if (app) navigate('/app/manutencao');
            
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Login | Urbanky</title>
            </Helmet>
            
            <LoadingU loading={loading} />

            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />

            <Box className={theme.boxContainer}>
                <Container maxWidth="md" sx={{ maxWidth: '776px  !important' }}>
                    <Formik
                        initialValues={{
                            cpf_passport: '',
                            ddi: countries[29].fone,
                            choise: '',
                            tel: '',
                            code: '',
                            email: '',
                            name: '',
                            social_name: '',
                            cep: '',
                            street: '',
                            neighborhood: '',
                            city: '',
                            state: '',
                            street_number: '',
                            complement: '',
                            password: '',
                            confirmPassword: '',
                            docSelect: false,
                            // terms: false,
                            sessionType: 'create_account'
                        }}
                        onSubmit={async (values) => {
                            if (toContinue()) {
                                setLoading(true);
                                await checkCode(values);
                            }
                        }}
                    >
                        {({ handleChange, handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    spacing={4}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h2"
                                        >
                                            Cadastro Rápido
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Person sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Nome Completo</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            error={!errors.name.valid}
                                            name="name"
                                            type="text"
                                            value={values.name}
                                            variant="standard"
                                            placeholder="Nome Completo"
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Person sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Nome Social</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            name="social_name"
                                            type="text"
                                            value={values.social_name}
                                            variant="standard"
                                            placeholder="Como gostaria de ser chamado"
                                            margin="dense"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex" position="relative">
                                            <Box display="flex">
                                                <FeaturedPlayList sx={{ color: "#666", mr: 1 }} />
                                                <Typography color="#666">{!values.docSelect ? 'CPF' : 'Passaporte'}</Typography>
                                            </Box>

                                            <Box display="flex" alignItems="center"  position="absolute" top={-7} right={0}>
                                                <Typography color="#666">{values.docSelect ? 'CPF' : 'Passaporte'}</Typography>
                                                                
                                                <Switch
                                                    checked={values.docSelect}
                                                    name="docSelect"
                                                    color="primary"
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                        </Box>
                                            <TextField
                                                fullWidth
                                                error={!errors.cpf_passport.valid}
                                                name="cpf_passport"
                                                variant="standard"
                                                placeholder={!values.docSelect ? 'CPF' : 'Passaporte'}
                                                margin="dense"
                                                value={values.cpf_passport}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <PhoneAndroid sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Celular</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            error={!errors.tel.valid}
                                            id="tel"
                                            name="tel"
                                            variant="standard"
                                            placeholder="(99) 99999-9999"
                                            margin="dense"
                                            inputProps={{ maxLength: 16 }}
                                            value={values.tel}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Autocomplete
                                                            autoHighlight
                                                            id="ddi"
                                                            value={acronym}
                                                            onChange={(e, newValue) => {
                                                                values.ddi = newValue.fone;
                                                                setAcronym(newValue);
                                                            }}
                                                            options={countries}
                                                            getOptionLabel={(country) => country.iso3}
                                                            renderOption={(props, country) => (
                                                                <MenuItem
                                                                    {...props}
                                                                    key={country.codigo}
                                                                    sx={{
                                                                        display: 'flex !important',
                                                                        padding: '4px !important',
                                                                        gap: 1
                                                                        
                                                                    }}
                                                                >
                                                                    <img
                                                                        loading="lazy"
                                                                        width="25"
                                                                        src={`https://countryflagsapi.com/svg/${country.iso.toLowerCase()}`}
                                                                        srcSet={`https://flagcdn.com/w40/${country.iso.toLowerCase()}.png 2x`}
                                                                        alt=""
                                                                    />
                                                                    {`${country.iso3} +${country.fone.replace('0', '').replace('0', '')}`}
                                                                </MenuItem>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                            sx={{
                                                                width: 130,
                                                                '& fieldset': { border: 'none' }
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onBlur={handleBlur}
                                            onChange={ handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Email sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">E-mail</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            error={!errors.email.valid}
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            variant="standard"
                                            placeholder="E-mail"
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <LocalOffer sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">CEP</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            id="cep"
                                            error={!errors.cep.valid}
                                            type="text"
                                            name="cep"
                                            value={values.cep}
                                            variant="standard"
                                            placeholder="CEP / Zip code"
                                            margin="dense"
                                            inputProps={{ maxLength: 9 }}
                                            onBlur={handleBlur}
                                            onChange={(e) => {
                                                handleCEP(e, values, handleChange);
                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Signpost sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Rua</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            id="street"
                                            error={!errors.street.valid}
                                            type="text"
                                            name="street"
                                            value={values.street}
                                            variant="standard"
                                            placeholder="Rua, Av, Travessa..."
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Money sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Número</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            id="street_number"
                                            type="text"
                                            name="street_number"
                                            value={values.street_number}
                                            variant="standard"
                                            placeholder="Número"
                                            margin="dense"
                                            inputProps={{ maxLength: 6 }}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Apartment sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Bairro</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            id="neighborhood"
                                            error={!errors.neighborhood.valid}
                                            type="text"
                                            name="neighborhood"
                                            value={values.neighborhood}
                                            variant="standard"
                                            placeholder="Bairro"
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Apartment sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Complemento</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            type="text"
                                            name="complement"
                                            value={values.complement}
                                            variant="standard"
                                            placeholder="Ap, Casa, Fundos..."
                                            margin="dense"
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 35 }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Apartment sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Cidade</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            id="city"
                                            error={!errors.city.valid}
                                            type="text"
                                            name="city"
                                            value={values.city}
                                            variant="standard"
                                            placeholder="Cidade"
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Apartment sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">UF</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            id="state"
                                            error={!errors.state.valid}
                                            type="text"
                                            name="state"
                                            value={values.state}
                                            variant="standard"
                                            placeholder="UF"
                                            margin="dense"
                                            inputProps={{ maxLength: 2 }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Password sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Senha</Typography>
                                        </Box>
                                        
                                        <InputMask
                                            mask="999999"
                                            maskChar={null}
                                            value={values.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {() =>
                                                <TextField
                                                    fullWidth
                                                    error={!errors.password.valid}
                                                    type="password"
                                                    name="password"
                                                    variant="standard"
                                                    placeholder="Senha"
                                                    margin="dense"
                                                />
                                            }
                                        </InputMask>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Box display="flex">
                                            <Password sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Confirmar Senha</Typography>
                                        </Box>
                                        
                                        <InputMask
                                            mask="999999"
                                            maskChar={null}
                                            value={values.confirmPassword}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {() =>
                                                <TextField
                                                    fullWidth
                                                    error={!errors.confirmPassword.valid}
                                                    type="password"
                                                    name="confirmPassword"
                                                    variant="standard"
                                                    placeholder="Confirmar senha"
                                                    margin="dense"
                                                />
                                            }
                                        </InputMask>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6} display="flex" alignItems="end">
                                        <Box display="flex" alignItems="center">
                                            <Checkbox
                                                checked={values.terms}
                                                name="terms"
                                                onChange={handleChange}
                                                sx={{ color: "#666" }}
                                            />

                                            <Typography variant="caption" color="#666">
                                                Li e aceito os {' '}
                                                <Link
                                                    href={`${urbanky}/termos-e-condicoes.html`}
                                                    target="_blank"
                                                    underline="always"
                                                    color="#666"
                                                >
                                                   termos & Condições Urbanky
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </Grid> */}

                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        sx={{ gap: 3 }}
                                    >
                                        <ModalConfirmartion
                                            barter={barter}
                                            handleChange={handleChange}
                                            handleSubmit={handleSubmit}
                                            registerValidations={validations}
                                            setBarter={setBarter}
                                            setLoading={setLoading}
                                            values={values}
                                        />

                                        <Button
                                            className={theme.btnSubmit}
                                            variant="contained"
                                            color="ternary"
                                            size="large"
                                            startIcon={<ArrowBack />}
                                            onClick={() => window.history.back()}
                                        >
                                            Login
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    )
}