import { createContext, useContext, useRef, useState } from 'react';
import { useErrors } from '../../utils/hooks/useErrors';

import { Box, Grid, Switch, TextField, Typography } from '@mui/material';
import { Email, FeaturedPlayList, PhoneAndroid } from '@mui/icons-material';
import { SnackbarAlert, triggerAlert } from '../ShowAlerts';

import { docValidation, tel_emailValidation } from '../../utils/validations/userValidations';

import InputMask from 'react-input-mask';

export default function RegisteredDOC({ handleChange, setValidations, values }) {
    const componentRef = useRef();

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(createContext({
        cpf_passport: docValidation,
        tel_email: tel_emailValidation
    }));

    const [errors, fieldsValidations] = useErrors(validations);

    function handleOpenAlert(text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            text: text
        });
    }

    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false
        });
    }

    function handleBlur(e) {
        setValidations(validations);
        fieldsValidations(e);
        triggerAlert(e, validations, handleOpenAlert, handleCloseAlert);
    }

    return (
        <>
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box display="flex" position="relative">
                        <Box display="flex">
                            <FeaturedPlayList sx={{ color: "#666", mr: 1 }} />
                            <Typography color="#666">{!values.docSelect ? 'CPF' : 'Passaporte'}</Typography>
                        </Box>

                        <Box display="flex" alignItems="center"  position="absolute" top={-7} right={0}>
                            <Typography color="#666">{values.docSelect ? 'CPF' : 'Passaporte'}</Typography>
                                            
                            <Switch
                                checked={values.docSelect}
                                name="docSelect"
                                color="primary"
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>
                        <TextField
                            fullWidth
                            error={!errors.cpf_passport.valid}
                            id="cpf_passport"
                            name="cpf_passport"
                            variant="standard"
                            placeholder={!values.docSelect ? 'Digite o CPF cadastrado' : 'Digite o passaporte cadastrado'}
                            value={values.cpf_passport}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            sx={{ m: 0 }}
                        />
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" position="relative">
                        <Box display="flex">
                            {
                                !values.sendSelect
                                    ? <PhoneAndroid sx={{ color: "#666", mr: 1 }} />
                                    : <Email sx={{ color: "#666", mr: 1 }} />
                            }
                            
                            <Typography color="#666">E-mail</Typography>
                        </Box>

                        {/* <Box display="flex" alignItems="center"  position="absolute" top={-7} right={0}>
                            <Typography color="#666">{values.sendSelect ? 'Telefone' : 'Email'}</Typography>
                                            
                            <Switch
                                checked={values.sendSelect}
                                name="sendSelect"
                                color="primary"
                                onChange={handleChange}
                            />
                        </Box> */}
                    </Box>

                    {
                        !values.sendSelect
                            ?
                                <InputMask
                                    mask={'(99) 99999-9999'}
                                    maskChar={null}
                                    value={values.tel_email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                >
                                    {
                                        () =>
                                            <TextField
                                                fullWidth
                                                error={!errors.tel_email.valid}
                                                id="tel"
                                                name="tel_email"
                                                variant="standard"
                                                placeholder="Telefone cadastrado"
                                                sx={{ m: 0 }}
                                            />
                                    }
                                </InputMask>
                            :
                                <TextField
                                    fullWidth
                                    error={!errors.tel_email.valid}
                                    id="tel"
                                    name="tel_email"
                                    value={values.tel_email}
                                    variant="standard"
                                    placeholder="E-mail cadastrado"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    sx={{ m: 0 }}
                                />
                    }
                </Grid>
            </Grid>
        </>
    );
}