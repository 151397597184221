import { createTheme } from '@mui/material';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1366,
            xl: 1920
        }
    },
    
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: { textDecoration: 'none' }
            }
        },

        MuiContainer: {
            styleOverrides: {
                root: {
                    padding: 40,
                    borderRadius: 16,
                    backgroundColor: '#fefefe',
                    margin: 12,

                    '@media (max-width: 414px)': {
                        padding: 10,
                        width: '100%'
                    }
                }
            }
        },

        MuiButton: {
            styleOverrides:{
                root: {
                    padding: '8px 16px',
                    borderRadius: 8,
                    boxShadow: '0px 2px 4px #00000029',
                    textTransform: 'none',
                    fontSize: '0.875rem',
                    fontWeight: 'medium',
                    lineHeight: 1.75,
                    transition: 'all .75s',
            
                    '&:hover': {
                        color: 'whitesmoke',
                        backgroundColor: '#000',
                        boxShadow: '0px 5px 10px #cdff0599'
                    }
                }
            }
        },

        MuiPaper: {
            styleOverrides: {
                root: { backgroundColor: '#fff' }
            }
        }
    },

    palette: {
        background: {
            default: '#fefefe',
            paper: '#111',
            accordion: '#090909'
        },
        
        primary: {
            contrastText: '#ccff00',
            main: '#000'
        },
        
        secondary: {
            contrastText: '#000',
            main: '#cdff00'
        },
        
        ternary: {
            contrastText: '#000',
            main: '#fefefe'
        },

        quaternary: {
            contrastText: '#fff',
            main: '#fc0065'
        },

        soon: {
            contrastText: '#bbb',
            main: '#fefefe'
        },

        soonOutline: {
            contrastText: '#fefefe',
            main: '#bbb'
        },

        btnSendCode: {
            contrastText: '#fefefe',
            main: '#0000ff'
        },
        
        textField: {
            contrastText: '#000',
            main: '#666'
        },
        
        text: {
            primary: '#000',
            secondary: '#666',
            ternary: 'whitesmoke'
        }
    },

    shadows: [
        'none',
        '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
        '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
    ],

    typography: {
        h1: {
            fontWeight: 500,
            fontSize: 35,
            letterSpacing: '-0.24px'
        },
        h2: {
            fontWeight: 500,
            fontSize: 29,
            letterSpacing: '-0.24px'
        },
        h3: {
            fontWeight: 500,
            fontSize: 24,
            letterSpacing: '-0.06px'
        },
        h4: {
            fontWeight: 500,
            fontSize: 20,
            letterSpacing: '-0.06px'
        },
        h5: {
            fontWeight: 500,
            fontSize: 16,
            letterSpacing: '-0.05px'
        },
        h6: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '-0.05px'
        },
            overline: {
            fontWeight: 500
        }
    }
});

export default theme;
