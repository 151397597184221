import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceData } from 'react-device-detect';
import { Box, Button, Card, Checkbox, Container, Grid, Typography } from '@mui/material';
// import { AccessibleForward, ArrowBack, ChevronRight, Home } from '@mui/icons-material';
import { Formik } from 'formik';

import { eventTicket, eventDateTransport } from '../utils/services/eventData';
// import { androidHref, appleHref } from '../utils/services/urls';
import { login } from '../utils/services/access';

import Helmet from 'react-helmet';
// import OptionsVoucher from '../components/buy-vouchers/OptionsVoucher';
// import VoucherQTY from '../components/buy-vouchers/VoucherQTY';
import Footer from '../components/Footer';
import LoadingU from '../components/LoadingU';

import layoutPage from '../theme/pageThemes';
import getEventImage from '../utils/fx/getEventImage';
import appMaintenance from '../utils/services/appMaintenance';
import StepsVoucher from '../components/buy-vouchers/StepsVoucher';

export default function BuyVoucher() {
    const logged = login();
    const theme = layoutPage();
    const navigate = useNavigate();
    const deviceInfo = useDeviceData();

    const componentRef = useRef();

    const event = JSON.parse(sessionStorage.getItem('event'));
    const banner = '/img/banners/city.png';
    const imgURL = event && getEventImage(event.pictures, 'banner');
    
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);

    function nextPage(values) {
        sessionStorage.setItem('vouchers', JSON.stringify(values));

        if (values.has_campaing === true) navigate('/app/eventos/comprar-voucher/donates');
        else {
            logged
                ? navigate('/app/pagamento')
                : navigate('/app/login');
        }
    }

    async function loadPage() {
        try {
            const app = await appMaintenance();

            // if (deviceInfo.os.name.toLowerCase === 'ios') window.location.replace(appleHref);
            // if (deviceInfo.os.name.toLowerCase === 'android') window.location.replace(androidHref);
    
            if (app) {
                setLoading(false);
                navigate('/app/manutencao');

            } else {
                setLoading(false);
                if (!event) navigate('/app/eventos');
                else {
                    const ticketData = await eventTicket(event.id);
                    
                    setTickets(ticketData);
                    setLoading(false);
                }
            }
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Eventos | Urbanky</title>
            </Helmet>
            <LoadingU loading={loading} />
            {
                event &&
                    <>
                        <Box
                            sx={{
                                pt: 8,
                                width: '100%',
                                height: '45vh',
                                display: 'flex',
                                justifyContent: 'center',
                                backgroundImage: `url(${banner})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',

                                '@media (max-width: 599px)': {
                                    height: '30vh',
                                    backgroundPosition: 'center'
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    maxWidth: 'calc(45vh * 2)',
                                    backgroundImage: `url(${imgURL})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                        </Box>
                        
                        <Container maxWidth="sm" sx={{ m: '20px auto', maxWidth: '412px !important' }}>
                            <Formik
                                initialValues={{
                                    checkedPCD: false,
                                    client_token: event.client_token,
                                    event_id: event.id,
                                    event_date_id: event.event_date_id,
                                    boarding_point_id: '',
                                    trip_id: '',
                                    campaing_id: '',
                                    coupom_id: '',
                                    device_info: {
                                        device_name: '',
                                        device_model: deviceInfo.browser.name,
                                        device_os_version: deviceInfo.browser.version
                                    },
                                    has_campaing: event.has_campaing,
                                    has_coupom: event.has_coupom,
                                    tickets: {
                                        fee_convenience: '',
                                        ticket_id: '',
                                        qty: 0,
                                        quantity: []
                                    },
                                    voucher_type: '',
                                    amount: 0
                                }}
                                onSubmit={values => nextPage(values)}
                            >
                                {({ handleChange, handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box sx={{ mb : 3 }}>
                                            <Typography color="textPrimary" variant="h2">
                                                {event.event_name}
                                            </Typography>
                                        </Box>

                                        <Grid
                                            container
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <StepsVoucher
                                                componentRef={componentRef}
                                                activeStep={activeStep}
                                                event={event}
                                                handleChange={handleChange}
                                                setActiveStep={setActiveStep}
                                                setLoading={setLoading}
                                                theme={theme}
                                                tickets={tickets}
                                                values={values}
                                            />
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </Container>
                        <Footer marginTop={20} />
                    </>
            }
        </>
    );
}