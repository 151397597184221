import { Link } from "@mui/material"
import { androidHref, appleHref } from "../utils/services/urls"

export function Logo(props) {
    return (
        <div>
            <img
                {...props}
                style={{ padding: 0 }}
                alt="Logo"
                src="/img/logo.svg"
            />
        </div>
    )
}

export function LogoU(props) {
    return (
        <div>
            <img
                {...props}
                style={{ padding: 0 }}
                alt="Logo"
                src="/img/favicon.svg"
            />
        </div>
    )
}

export function AppleStore(props) {
    return (
        <Link href={appleHref} target="_blank">
            <img
                {...props}
                style={{ padding: 0 }}
                alt="Logo"
                src="/img/footer-appstore.svg"
            />
        </Link>
    )
}

export function PlayStore(props) {
    return (
        <Link href={androidHref} target="_blank">
            <img
                {...props}
                style={{ padding: 0 }}
                alt="Logo"
                src="/img/footer-playstore.svg"
            />
        </Link>
    )
}