import { useNavigate } from 'react-router-dom';
import { useDeviceData } from 'react-device-detect';

import { Box, Button, Grid, Typography } from '@mui/material';

// import { androidHref, appleHref } from '../../utils/services/urls';
import { getMonth } from '../../utils/fx/getDate';

import layoutPage from '../../theme/pageThemes';
import dataEvent from '../../utils/fx/dataEvent';

export default function CardDatesEvents({ date, event }) {
    const theme = layoutPage();
    const navigate = useNavigate();
    const deviceInfo = useDeviceData();

    return (
        <Grid item xs={6} sm={4} md={3}>
            <Button
                className={!event.setting.has_sale ? theme.btnSoon : theme.btnSubmit}
                color={!event.setting.has_sale ? 'soon' : 'secondary'}
                variant="contained"
                onClick={() => {
                    // if (deviceInfo.os.name.toLowerCase() === 'ios') window.location.href = appleHref;
                    // else if (deviceInfo.os.name.toLowerCase() === 'android') window.location.href = androidHref;
                    // else {
                    //     dataEvent(date._id, event);
                    //     navigate('/app/eventos/comprar-voucher');
                    // }

                    if (!event.setting.has_gratuity) {
                        dataEvent(date._id, event);
                        navigate('/app/eventos/comprar-voucher');
                    }
                }}
                sx={{
                    p: '8px 16px',
                    width: '100%',
                    boxShadow: '0px 0px 10px #cdff0599 !important'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            textAlign="center"
                            fontWeight={700}
                            fontSize={20}
                        >
                            {date.begin_date.slice(0, 2)}
                        </Typography>
                        <Typography
                            textAlign="center"
                            fontWeight={700}
                            fontSize={20}
                            ml={1}
                        >
                            {getMonth(date)}
                        </Typography>
                    </Box>
                    <Typography
                        textAlign="justify"
                        fontWeight={700}
                        fontSize={18}
                    >
                        {date.begin_date.slice(6, 10)}
                    </Typography>
                </Box>
            </Button>
        </Grid>
    )
}