import { useEffect, useState } from 'react';
import { Box, Grid, MobileStepper, Typography } from '@mui/material';

import { autoPlay } from 'react-swipeable-views-utils';

import SwipeableViews from 'react-swipeable-views';
import ModalBuyEvent from '../ModalBuyEvent';

import layoutPage from '../../theme/pageThemes';
import getEventImage from '../../utils/fx/getEventImage';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function SlideImages({ events }) {
    const theme = layoutPage();
    const maxSteps = events.length;
    const [activeStep, setActiveStep] = useState(0);

    function handleStepChange(step) {
        setActiveStep(step);
    }

    useEffect(() => {
        const e = document.querySelectorAll('.MuiMobileStepper-dot');
        
        e.forEach((el, i) => {
            el.onclick = () => handleStepChange(i);
        });
    }, [activeStep]);

    return (
        <div className={theme.slideContainer}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                interval={7000}
                enableMouseEvents
                onChangeIndex={handleStepChange}
                sx={{
                    justifyContent: 'center',
                    
                    '& #text': {
                        backgroundColor: '#fff !important'
                    }
                }}
            >
                
                {
                    events.map((event, i) => {
                        return (
                            <Grid
                                key={i}
                                container
                                sx={{
                                    width: '100%',
                                    borderRadius: 16,
                                    height: 'calc(80vh - 64px)',
                                    minHeight: 580,
                                    maxHeight: 744,
                                    backgroundColor: '#F5F5F5',

                                    '@media (max-height: 768px)': {
                                        // height: '80vh',
                                        borderRadius: 4
                                    },

                                    '@media (max-height: 768px) and (width: 1366px)': {
                                        minHeight: 680,
                                    }
                                }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        width: '100%',
                                        height: '66%',
                                        borderRadius: 2,
                                        maxHeight: 490,
                                        boxShadow: '0px 5px 15px #000',
                                        background: `center no-repeat url(${getEventImage(event.pictures, 'slide_site') || getEventImage(event.pictures, 'banner')})`,
                                        backgroundSize: 'cover',

                                        '@media (max-height: 768px) and (width: 1366px)': {
                                            backgroundSize: '110% 100%',
                                        },

                                        '@media (max-width: 414px)': {
                                            height: '75%',
                                            backgroundImage: `url(${getEventImage(event.pictures, 'site_slide_responsive') || getEventImage(event.pictures, 'banner')})`
                                        }
                                    }}
                                />

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        width: '100%',
                                        height: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '0 min(24px, 5vw) min(24px, 5vw)'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            gap: 'min(24px, 5vw)',
                                            position: 'relative',
                                            // alignSelf: 'flex-start',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                color: "#FC0065",
                                                fontSize: "min(36px, 7.75vw)"
                                            }}
                                        >
                                            {event.event_name}
                                        </Typography>
                                        
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color: "#FC0065",
                                                fontSize: 'min(16px, 5vw)'
                                            }}
                                        >
                                            {event?.dates}
                                        </Typography>
                                        
                                        <Typography
                                            sx={{
                                                width: '40%',
                                                position: 'absolute',
                                                right: 'min(24px, 5vw)',
                                                bottom: 'calc((min(24px, 5vw) * -2) - 8px)',
                                                fontSize:'min(16px, 5vw)'
                                            }}
                                        >
                                            {event?.event_address.complement}
                                        </Typography>
                                    </Box>

                                    <ModalBuyEvent
                                        className={!event.setting.has_sale ? theme.btnSoon : theme.btnSlide}
                                        size="large"
                                        event={event}
                                        variant="outlined"
                                        color={!event.setting.has_sale
                                            ? 'soonOutline'
                                            : 'quaternary'}
                                        title={!event.setting.has_sale
                                            ? 'Em breve'
                                            : !event.setting.has_gratuity
                                                ? 'Comprar'
                                                : 'Gratuito'
                                        }
                                        style={{
                                            padding: 2,
                                            width: '40%',
                                            boxShadow: 'none',
                                            fontSize: 'min(30px, 5vw)'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </AutoPlaySwipeableViews>
            
            <MobileStepper
                id="slide"
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            />
        </div>
    );
}