import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { listEvents } from '../utils/services/eventData';

import Helmet from 'react-helmet';
import LoadingU from '../components/LoadingU';
import NoEvents from '../components/events/NoEvents';
import WithEvents from '../components/events/WithEvents';
import appMaintenance from '../utils/services/appMaintenance';

export default function Events() {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(true);
    const [component, setComponent] = useState(null);

    async function loadPage() {
        try {
            const app = await appMaintenance();
            
            if (app) {
                setLoading(false);
                navigate('/app/manutencao');

            } else {
                const list = await listEvents();

                list && list.length > 0
                    ? setComponent(<WithEvents events={list.sort((a, b) => a.display_order < b.display_order ? -1 : 1)} />)
                    : setComponent(<NoEvents />);

                setLoading(false);
            }
    
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw setComponent(<NoEvents />);
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Eventos | Urbanky</title>
            </Helmet>
            <LoadingU loading={loading} />
            {component}
        </>
    );
}