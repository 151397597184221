import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Card, Container, Grid, Typography } from '@mui/material';
import { AccessTime, InsertInvitation, LocationOn } from '@mui/icons-material';

import { listVoucher } from '../utils/services/vouchers';
import { userData } from '../utils/services/access';
import { weekDay } from '../utils/fx/getDate';

import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import LoadingU from '../components/LoadingU';

import appMaintenance from '../utils/services/appMaintenance';
import ModalDownloadVoucher from '../components/vouchers/ModalDownloadVoucher';

export default function Vouchers() {
    const navigate = useNavigate();

    const [vouchers, setVouchers] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadPage() {
        try {
            const user = userData();
            const app = await appMaintenance();
            const list = await listVoucher(user.id);
            setLoading(false);

            if (app) navigate('/app/manutencao');
            else setVouchers(list);
            
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Vouchers | Urbanky</title>
            </Helmet>
            <LoadingU loading={loading} />
            <Container
                maxWidth="lg"
                sx={{
                    m: '67px auto',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 'calc(60vh - 64px)',
                    backgroundColor: '#111'
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    gap={1}
                    mt={1}
                >
                    {
                        vouchers.filter(voucher => voucher.status !== 'Expired').length < 1
                            ?
                                <Card
                                    sx={{
                                        p: '18px 8px',
                                        gap: 2,
                                        width: '100%',
                                        color: 'whitesmoke',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        backgroundColor: '#090909',
                                        boxSizing: 'border-box',

                                        '@media (max-Width: 700px)': {
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }
                                    }}
                                >
                                    <Typography
                                        variant="h2"
                                        color="whitesmoke"
                                        fontSize={24}
                                        textAlign="center"
                                        mb={1}
                                    >
                                        Ainda não possui tickets
                                    </Typography>
                                </Card>
                            : vouchers.filter(voucher => voucher.status !== 'Expired').map((voucher, i) => (
                                <Card
                                    key={i}
                                    sx={{
                                        p: '8px',
                                        gap: 2,
                                        width: '100%',
                                        color: 'whitesmoke',
                                        boxSizing: 'border-box',
                                        backgroundColor: '#090909'
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={2}
                                        >
                                            <Avatar src={voucher.ticket.picture} sx={{ height: 120, width: 120 }} />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={5}
                                        >
                                            <Typography
                                                variant="h2"
                                                color="whitesmoke"
                                                fontSize={24}
                                                mb={1}
                                            >
                                                {voucher.event.event_name}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <InsertInvitation />
                                                <Typography variant="h6" ml={1}>
                                                    {`${voucher.date.begin_date.substring(0, 10)} - ${weekDay(voucher.date.begin_date.substring(0, 10))}`}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AccessTime />
                                                <Typography variant="h6" ml={1}>
                                                    {voucher.transport.trip.departure_time}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <LocationOn />
                                                <Typography variant="h6" ml={1}>
                                                    {voucher.transport.boarding_name}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={2}
                                        >
                                            <Box
                                                sx={{
                                                    width: 120,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 2
                                                }}
                                            >
                                                <Typography
                                                    variant="h2"
                                                    fontSize={24}
                                                    color='whitesmoke'
                                                >
                                                    Status
                                                </Typography>
                                                
                                                <Typography
                                                    variant="h6"
                                                    textAlign='center'
                                                    p={1}
                                                    sx={{
                                                        borderRadius: 2,
                                                        backgroundColor: voucher.status === 'Ativo'
                                                                            ? 'green'
                                                                            : voucher.status === 'Não aprovado'
                                                                                ? 'red'
                                                                                : 'gray'
                                                    }}
                                                >
                                                    {
                                                        voucher.status === 'Ativo'
                                                            ? 'Ativo'
                                                            : voucher.status === 'Não aprovado'
                                                                ? 'Não aprovado'
                                                                : 'Processando'
                                                    }
                                                </Typography>

                                                <ModalDownloadVoucher voucher={voucher} />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>
                            ))
                    }
                </Box>
            </Container>
            <Footer />
        </>
    )
}