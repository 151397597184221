import axios from 'axios';

const timeout = 5000;

export async function payCard(user, voucher, cardData) {
    const fields = JSON.stringify({
        // user
        user_data: {
            uid: user.id,
            fullname: user.preferred_name,
            contact_phone: user.contact_phone,
            email_address: user.email_address,

            device: {
                type: 'Web',
                version: voucher.device_info.device_model
            }
        },

        // voucher
        client_token: voucher.client_token,
        voucher_type: voucher.voucher_type,
        event_id: voucher.event_id,
        event_date_id: voucher.event_date_id,
        boarding_point_id: voucher.boarding_point_id,
        route_trip_id: voucher.trip_id,
        campaing_id: voucher.campaing_id,
        cupom_id: cardData.cupom_id,
        has_pcd: voucher.checkedPCD,
        
        ticket: {
            qty: voucher.tickets.qty,
            ticket_id: voucher.tickets.ticket_id
        },
    
        
        // card data
        installment: cardData.installment,

        billing_payment: {
            card_id: cardData.card_id,
            pan: cardData.cardNumber,
            card_holder_name: cardData.holderName,
            securityCode: cardData.cardCVC,
            expiryMonth: cardData.cardExpiry.substring(0, 2),
            expiryYear: `20${cardData.cardExpiry.substring(3, 5)}`,
            scheme: cardData.cardFlag
        },
    
    });

    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/voucher`,
        method: 'post',
        timeout: timeout,
        data: fields,
        headers: { 'Content-Type': 'application/json' }
    };
    
    return await axios(config)
        .then(() => {
            return {
                valid: true,
                confirm: 'Compra em processo',
                info: 'Baixe o app para visualizar seu voucher.'
            };
        })
        .catch(({ response }) => {
            if (response.data.message.code === 'E00007') {
                return {
                    valid: false,
                    confirm: '',
                    info: 'A quantidade solicitada não está mais disponível!'
                };
            } else {
                return {
                    valid: false,
                    consfirm: '',
                    info: 'Não foi possível realizar a compra, tente novamente mais tarde!'
                };
            }
        });
}

export async function saveCard(uid, cardData) {
    const fields = JSON.stringify({
        pan: cardData.cardNumber,
        card_holder_name: cardData.holderName,
        securityCode: '',
        expiryMonth: cardData.cardExpiry.substring(0, 2),
        expiryYear: `20${cardData.cardExpiry.substring(3, 5)}`,
        scheme: cardData.cardFlag
    });

    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/user/${uid}/wallet/credit_card`,
        method: 'post',
        timeout: timeout,
        data: fields,

        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'application/json'
        }
    };

    return await axios(config)
        .then(() => {
            return {
                severity: 'success',
                text: 'Cartão salvo com sucesso! Finalizando sua compra.'
            }
        })
        .catch(() => {
            return {
                severity: 'error',
                text: 'Não foi possível salvar o cartão! Finalizando sua compra.'
            }
        });
}

export async function listCardsFavorite(uid) {
    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/user/${uid}/wallet/credit_card`,
        method: 'get',
        timeout: timeout,
        headers: {
            client_token: process.env.REACT_APP_CLIENT_TOKEN,
            'Content-Type': 'applicattion/json'
        }
    };

    await axios(config)
        .then(({ data }) => sessionStorage.setItem('myCards', JSON.stringify(data.data)))
        .catch(({ response }) => sessionStorage.setItem('myCards', JSON.stringify(response.data.data)));
}

export async function deleteCard(uid, wcid) {
    const config = {
        url: `${process.env.REACT_APP_API_PAY}/v1/user/${uid}/wallet/credit_card/${wcid}`,
        method: 'delete',
        timeout: timeout,
        headers: { client_token: process.env.REACT_APP_CLIENT_TOKEN }
    };

    return await axios(config)
        .then(response => {
            if (response.status === 200 || response.status === 201) return { open: true, severity: 'success', text: 'Cartão deletado!' };
        })
        .catch(error => {
            if (error.response.status !== 200 || error.response.status !== 201) return { open: false, severity: 'error', text: 'Houve um error, tente novamente mais tarde!' };
        });
}

export async function checkedCupom(event_id, cupom, amount) {
    const fields = JSON.stringify({ cupom: cupom.toUpperCase() });

    const discount = {
        id: '',
        value: 0
    };

    const config = {
        url: `${process.env.REACT_APP_PCMS}/v1/cupom/validate?event_id=${event_id}`,
        data: fields,
        method: 'post',
        timeout: timeout,
        headers: { 'Content-Type': 'application/json' }
    };

    return await axios(config)
        .then(({ data }) => {
            discount.id = data.data._id;

            if (data.data.type === 'percent') {
                discount.value = amount * (JSON.parse(data.data.discount_value) / 100);
                return discount;
            } else {
                discount.value = JSON.parse(data.data.discount_value);
                return discount;
            }
        })
        .catch(() =>  discount);
}