import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDeviceData } from 'react-device-detect';
import { disableBtn, useErrors } from '../utils/hooks/useErrors';

import { Helmet } from 'react-helmet';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import {  Password, Person } from '@mui/icons-material';
import { Formik } from 'formik';
import { SnackbarAlert, triggerAlert } from '../components/ShowAlerts';

import { passwordValidation, userValidation } from '../utils/validations/userValidations';
import { login, loginValidation } from '../utils/services/access';
// import { androidHref, appleHref } from '../utils/services/urls';

import LoadingU from '../components/LoadingU';

import layoutPage from '../theme/pageThemes';
import appMaintenance from '../utils/services/appMaintenance';

export default function Login() {
    const theme = layoutPage();
    const componentRef = useRef();
    const navigate = useNavigate();
    const deviceInfo = useDeviceData();
    
    const [loading, setLoading] = useState(true);

    const [openAlert, setOpenAlert] = useState({
        valid: false,
        severity: 'error',
        text: ''
    });
    
    const validations = useContext(
        createContext({
            user: userValidation,
            password: passwordValidation
        })
    );

    const [errors, fieldsValidation, toContinue] = useErrors(validations);

    function handleOpen(text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            text: text
        });
    }

    function handleClose() {
        setOpenAlert({
            ...openAlert,
            open: false,
            text: ''
        });
    }

    function handleBlur(e) {
        fieldsValidation(e);
        triggerAlert(e, validations, handleOpen, handleClose)
    }

    async function enterLogin(values) {
        try {
            const app = await appMaintenance();
            await loginValidation(values.user, values.password);
            setLoading(false);

            if (app) navigate('/app/manutencao');
            else {
                const isLogged = login();
                const vouchers = JSON.parse(sessionStorage.getItem('vouchers'));
                
                if (isLogged) {
                    vouchers
                        ? navigate('/app/pagamento', { replace: true })
                        : navigate('/', { replace: true })
                } else handleOpen('Usuário ou senha inválidos!');
            }
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    async function loadPage() {
        try {
            const app = await appMaintenance();
            const logged = await login();
            setLoading(false);

            // if (deviceInfo.os.name.toLowerCase === 'ios') window.location.replace(appleHref);
            // if (deviceInfo.os.name.toLowerCase === 'android') window.location.replace(androidHref);
            if (app) navigate('/app/manutencao');
            if (logged) navigate('/app/eventos', { replace: true });

        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Login | Urbanky</title>
            </Helmet>
            
            <LoadingU loading={loading} />

            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleClose}
            />
            
            <Box className={theme.boxContainer}>
                <Container maxWidth="sm" sx={{ maxWidth: '412px !important' }}>
                    <Formik
                        initialValues={{
                            user: '',
                            password: ''
                        }}
                        onSubmit={values => {
                            if (toContinue()) {
                                setLoading(true);
                                enterLogin(values);
                            }
                        }}
                    >
                        {({ handleChange, handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid item xs={12} mb={2}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h2"
                                        >
                                            Login
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex">
                                            <Person sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">CPF ou E-mail</Typography>
                                        </Box>

                                        <TextField
                                            fullWidth
                                            error={!errors.user.valid}
                                            name="user"
                                            value={values.user}
                                            variant="standard"
                                            placeholder="Digite o CPF ou E-mail cadastrado"
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 3 }}>
                                        <Box display="flex">
                                            <Password sx={{ color: "#666", mr: 1 }} />
                                            <Typography color="#666">Senha</Typography>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            error={!errors.password.valid}
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            variant="standard"
                                            placeholder="Senha"
                                            margin="dense"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />

                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 2 }}
                                    >
                                        <Button
                                            className={theme.btnSubmit}
                                            disabled={disableBtn(values, validations)}
                                            variant="contained"
                                            color="secondary"
                                            type="submit"
                                            size="large"
                                        >
                                            Faça seu login agora
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={5}
                                        sx={{ mt: 2 }}
                                    >
                                        <Button
                                            className={theme.btnSubmit}
                                            component={NavLink}
                                            to="/app/cadastrar"
                                            variant="contained"
                                            color="ternary"
                                            size="large"
                                        >
                                            Cadastro rápido
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{ mt: 2 }}
                                    >
                                        <Button
                                            className={theme.btnSubmit}
                                            component={NavLink}
                                            to="/app/recuperar-senha"
                                            variant="contained"
                                            color="ternary"
                                            size="large"
                                        >
                                            Recuperar senha
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    )
}