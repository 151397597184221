import { createContext, useContext, useEffect, useRef, useState } from 'react';

import {
    Backdrop,
    Box,
    Button,
    Container,
    Fade,
    Grid,
    Modal,
    TextField,
    Typography
} from '@mui/material';

import { SnackbarAlert, triggerAlert } from '../ShowAlerts';

import { sendSMS } from '../../utils/services/codesValidations';
import { showTime, startCounting } from '../../utils/fx/getTime';
import { disableBtn, useErrors } from '../../utils/hooks/useErrors';
import { codeValidation } from '../../utils/validations/userValidations';

import InputMask from 'react-input-mask';

import layoutPage from '../../theme/pageThemes';
import Choise from './Choise';

export default function ModalConfirmartion(props) {
    const theme = layoutPage();
    const componentRef = useRef();

    const {
        barter,
        handleChange,
        handleSubmit,
        registerValidations,
        setBarter,
        setLoading,
        values
    } = props;
    
    const [minuteCount, setMinuteCount] = useState('0');
    const [secondsCount, setSecondsCount] = useState('59');
    const [stop, setStop] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(createContext({ code: codeValidation }));

    const [errors, fieldsValidations] = useErrors(validations);
    
    
    // const codeButton = (values, handleChange, sendCode) => (<Choise values={values} handleChange={handleChange} sendCode={sendCode} />);
    const codeButton = (values, sendCode) => (
        <Button
            variant="text"
            color="btnSendCode"
            onClick={() => {
                values.choise = values.email;
                sendCode(values);
            }}
            sx={{ '@media (max-width: 280px)': { width: '100%' } }}
        >
            Clique para enviar o código!
        </Button>
    );
    
    function handleOpenModal() { setOpenModal(!openModal); }

    function handleCloseModal() {
        clearValues();
        handleCloseAlert();
        setOpenModal(!openModal);
    }
    
    function handleOpenAlert(text, severity = 'error') {
        setOpenAlert({
            ...openAlert,
            open: true,
            severity: severity,
            text: text
        });
    }
    
    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false,
            text: ''
        });
    }

    function handleBlur(e) {
        fieldsValidations(e);
        triggerAlert(e, validations, handleOpenAlert, handleCloseAlert);
    }

    function clearValues() {
        for (let field in validations) {
            const currentValues = { target: { name: field, value: '' } }
            handleChange(currentValues);
        }
    }

    async function sendCode(obj) {
        setLoading(true);
        const getCode = await sendSMS(obj);
        if (getCode) {
            setLoading(false);
            startCounting(barter, setMinuteCount, setSecondsCount);
            setBarter(true);

        } else {
            setLoading(false);
            handleOpenAlert('Verifique se os dados estão corretos!');
        }
    }

    useEffect(() => {
        // Trocar para envio de código quando acabar o timer.
        if (minuteCount === "0") setBarter(false);
    }, [minuteCount]);

    return (
        <>
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />

            <Button
                className={theme.btnSubmit}
                disabled={disableBtn(values, registerValidations)}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleOpenModal}
            >
                Continuar
            </Button>
            
            <Modal
                closeAfterTransition
                className={theme.modal}
                open={openModal}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={openModal} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Container className={theme.modalContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h2" color="primary" textAlign="left">
                                    Validação de Token
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={12} textAlign="left" sx={{ color: '#000' }}>
                                {/* {barter ? showTime(minuteCount, secondsCount, values.email) : codeButton(values, handleChange, sendCode)} */}
                                {barter ? showTime(minuteCount, secondsCount, values.email) : codeButton(values, sendCode)}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography color="primary" sx={{ '@media (max-width: 280px)': { textAlign: 'center' } }}>
                                    Insira o código recebido:
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                                display="flex"
                                flexWrap="wrap"
                                gap={2}
                                sx={{ '@media (max-width: 280px)': { justifyContent: 'center' } }}
                            >
                                <Box display={barter ? 'block' : 'none'}>
                                    <InputMask
                                        mask={'9999'}
                                        maskChar={null}
                                        value={values.code}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {
                                            () =>
                                                <TextField
                                                    className={theme.code}
                                                    error={!errors.code.valid}
                                                    id="code"
                                                    name="code"
                                                    variant="outlined"
                                                    placeholder="0000"
                                                />
                                        }
                                    </InputMask>
                                </Box>

                                <TextField
                                    className={theme.code}
                                    disabled={!barter}
                                    variant="outlined"
                                    placeholder="0000"
                                    sx={{ display: barter ? 'none' : 'block' }}
                                />

                                <Button
                                    disabled={disableBtn(values, validations)}
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                    sx={{ '@media (max-width: 280px)': { width: '100%' } }}
                                >
                                    Confirmar
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    color="ternary"
                                    size="large"
                                    variant="contained"
                                    onClick={handleCloseModal}
                                    sx={{ '@media (max-width: 280px)': { width: '100%' } }}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Fade>
            </Modal>
        </>
    );
}