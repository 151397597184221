import { MenuItem, TextField } from '@mui/material';

export default function BoardingPoints(props) {
    const {
        amount,
        boarding,
        errors,
        handleBlur,
        handleChange,
        theme,
        values
    } = props;

    return (
        <TextField
            fullWidth
            select
            id="select"
            color="textField"
            className={theme.boarding}
            disabled={amount < 1 ? true : false}
            error={!errors.boarding_point_id.valid}
            name="boarding_point_id"
            variant="outlined"
            value={values.boarding_point_id}
            placeholder="selecione um ponto de embarque"
            label="selecione um ponto de embarque"
            onBlur={handleBlur}
            onChange={handleChange}
        >
            {
                boarding.sort((a, b) => {
                    if (a.boarding_name > b.boarding_name) return 1;
                    else if (a.boarding_name < b.boarding_name) return - 1;
                    else return 0;
                }).map(item => (
                    <MenuItem
                        key={item._id}
                        value={item._id}
                    >
                        {item.boarding_name}
                    </MenuItem>
                ))
            }
        </TextField>
    );
}