import { Box, Container, Typography } from '@mui/material';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import layoutPage from '../theme/pageThemes';

export default function NotFound() {
    const theme = layoutPage();
    
    return (
        <>
            <Helmet>
                <title>404 | Urbanky</title>
            </Helmet>

            <Box className={theme.containerErrorPages}>
                <Container maxWidth="md" sx={{ backgroundColor: '#111' }}>
                    <Typography
                        variant="h1"
                        color="secondary"
                        align="center"
                    >
                        404: A página que você está procurando não está aqui
                    </Typography>
                    
                    <Typography
                        variant="subtitle2"
                        color="text.ternary"
                        align="center"
                        fontSize="1.1em"
                        mt={2}
                    >
                        Ou você tentou um caminho duvidoso ou veio aqui por engano. Seja o que for, tente usar a navegação
                    </Typography>

                    <Box sx={{ textAlign: 'center' }}>
                        <img
                            alt="Under development"
                            src="/img/undraw_page_not_found_su7k.svg"
                            style={{
                            marginTop: '15%',
                            display: 'inline-block',
                            maxWidth: '100%',
                            width: 360
                            }}
                        />
                    </Box>
                </Container>
            </Box>

            <Footer />
        </>
    )
}