import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import LoadingU from '../components/LoadingU';
import layoutPage from '../theme/pageThemes';
import appMaintenance from '../utils/services/appMaintenance';
import { SnackbarAlert } from '../components/ShowAlerts';
import { Container, Typography } from '@mui/material';
import { Formik } from 'formik';
import { Box } from '@mui/system';
import StepsRecover from '../components/recover-password/StepsRecover';
import { changePassword } from '../utils/services/codesValidations';

export default function RecoverPassword() {
    const theme = layoutPage();
    const navigate = useNavigate();
    const componentRef = useRef();

    const [loading, setLoading] = useState(true);

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    function handleOpenAlert(text) {
        setOpenAlert({
            ...openAlert,
            open: true,
            text: text
        });
    }

    function handleCloseAlert() {
        setOpenAlert({
            ...openAlert,
            open: false
        });
    }

    async function confirmChange(values) {
        const app = await appMaintenance();
        
        if (app) {
            setLoading(false);
            navigate('/app/manutencao');
        } else {
            const send = await changePassword(values);
    
            if (send.valid) {
                sessionStorage.setItem('proceed', send.valid);
                sessionStorage.setItem('msnConfirmation', JSON.stringify(send));
                handleCloseAlert();
                setLoading(false);
                navigate('/app/recuperar-senha/confirmacao');
    
            } else {
                setLoading(false);
                handleOpenAlert(send.text);
            }
        }
    }

    async function loadPage() {
        try {
            const app = await appMaintenance();
            setLoading(false);

            if (app) navigate('/app/manutencao');

            
        } catch {
            setLoading(false);
            sessionStorage.setItem('error', true);
            throw navigate('/app/erro-inesperado');
        }
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <>
            <Helmet>
                <title>Recuperar Senha | Urbanky</title>
            </Helmet>
            <LoadingU loading={loading} />

            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleCloseAlert}
            />
            
            <Box className={theme.boxContainer}>
                <Container maxWidth="sm" sx={{ maxWidth: '412px !important' }}>
                    <Formik
                        initialValues={{
                            cpf_passport: '',
                            tel_email: '',
                            code: '',
                            newPassword: '',
                            confirmPassword: '',
                            docSelect: false,
                            sendSelect: true,
                            sessionType: 'recovery_password'
                        }}
                        onSubmit={async (values) => {
                            setLoading(true);
                            await confirmChange(values);
                        }}
                    >
                        {({ handleChange, handleSubmit, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Recuperar Senha
                                    </Typography>

                                    <StepsRecover
                                        handleChange={handleChange}
                                        handleOpenAlert={handleOpenAlert}
                                        setLoading={setLoading}
                                        values={values}
                                    />
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
}