import { useState } from "react";
import { Box, Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import { sendCode, validateCodeCel } from "../../utils/services/codesValidations";
import { disableBtn } from "../../utils/hooks/useErrors";

import RegisteredDOC from "./RegisteredDOC";
import ValidateCode from "./ValidateCode";
import NewPassword from "./NewPassword";

const steps = (handleChange, setValidations, values) => [
    {
        label: 'Documento cadastrado',
        component:
            <RegisteredDOC
                handleChange={handleChange}
                setValidations={setValidations}
                values={values}
            />
    },

    {
        label: 'Validação de código',
        component:
            <ValidateCode
                handleChange={handleChange}
                setValidations={setValidations}
                values={values}
            />
    },

    {
        label: 'Nova senha',
        component:
            <NewPassword
                handleChange={handleChange}
                setValidations={setValidations}
                values={values}
            />
    }
];

export default function StepsRecover({ handleChange, handleOpenAlert, setLoading, values }) {
    const [activeStep, setActiveStep] = useState(0);
    const [validations, setValidations] = useState(null);

    const handleBack = () => setActiveStep(prev => prev - 1);

    const handleNext = async() => {
        setLoading(true);

        if (activeStep === 0) {
            const send = await sendCode(values);

            if (send) setActiveStep(prev => prev + 1);
            else handleOpenAlert('Houve um erro, tente novamente mais tarde!');

        } else if (activeStep === 1) {
            const code = await validateCodeCel(values);

            if (code) setActiveStep(prev => prev + 1);
            else handleOpenAlert('Código inválido!');
        }

        setLoading(false);
    };

    const handleReset = () => {
        for (let field in values) {
            if (field === 'docSelect') values[field] = false;
            else values[field] = '';

            console.log(values)
            values.sendSelect = true;
            values.sessionType = 'recovery_password';
        }
        
        setActiveStep(0);
    };

    function disabledBTN(values) {
        const newValues = {}

        if (activeStep === steps.length - 1) {
            if (values.newPassword === '') return true;
            else if (values.confirmPassword === '') return true;
        }

        if (validations) {
            for (let field in validations) newValues[field] = values[field];
            return disableBtn(newValues, validations);

        } else return true;
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {
                    steps(handleChange, setValidations, values).map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>

                            <StepContent>
                                {step.component}

                                <Box sx={{ m: '8px 0' }}>
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Anterior
                                        </Button>
                                        
                                        <Button
                                            disabled={disabledBTN(values)}
                                            variant="contained"
                                            color="secondary"
                                            type={index === steps.length - 1 ? 'submit' : 'button'}
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'Alterar senha' : 'Continuar'}
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))
                }
            </Stepper>

            <Box
                sx={{
                    mt: 5,

                    '@media (max-width: 414px)': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 3
                    }
                }}
            >
                <Button
                    disabled={activeStep < 1 ? true : false}
                    color="secondary"
                    variant="contained"
                    onClick={handleReset}
                >
                    Reiniciar
                </Button>

                <Button
                    color="ternary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => window.history.back()}
                    sx={{
                        ml: 3,

                        '@media (max-width: 414px)': {
                            m: 0
                        }
                    }}
                >
                    Login
                </Button>
            </Box>
        </Box>
    );
}