import { useState } from "react";
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import { AccessibleForward, ArrowBack, ChevronRight } from '@mui/icons-material';

import VoucherQTY from "./VoucherQTY";
import OptionsVoucher from "./OptionsVoucher";
import { eventDateTransport } from "../../utils/services/eventData";
import { SnackbarAlert } from "../ShowAlerts";

const steps = (props, { boarding }) => {
    const { componentRef, event, handleChange, theme, tickets, values } = props;

    return (
        [
            {
                label: 'Quantidade',
                component:
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        {
                            tickets.map((ticket, i) => {
                                values.voucher_type = ticket.type;

                                return (
                                    <Box
                                        key={i}
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            width: '100%',
                                            height: 94,
                                            boxSizing: "border-box",
                                            alignItems: 'flex-start'
                                        }}
                                    >
                                        <VoucherQTY
                                            ref={componentRef}
                                            handleChange={handleChange}
                                            ticket={ticket}
                                            fee_convenience={event.fee_convenience}
                                            values={values}
                                        />
                                    </Box>
                                );
                            })
                        }
                    </Grid>
            },

            {
                label: 'Quantidade',
                component:
                <Grid item xs={12}>
                    <OptionsVoucher
                        amount={values.amount}
                        boarding={boarding}
                        event={event}
                        handleChange={handleChange}
                        theme={theme}
                        values={values}
                    />
                </Grid>
            },
        ]
    );
};

export default function StepsVoucher(props) {
    const {
        activeStep,
        componentRef,
        event,
        handleChange,
        setActiveStep,
        setLoading,
        theme,
        values
    } = props;

    const [boarding, setBoarding] = useState([]);
    const [type, setType] = useState('button');

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });


    const disableBtn = (total, values) => {
        if (type === 'button') {
            if (total > 0) return false;
            else return true;
        } else {
            if (total > 0 && values.trip_id !== '') return false;
            else return true;
        }
    }

    const handleBack = () => {
        setType('button');
        setActiveStep(prev => prev < 1 ? 0 : prev - 1);
    };

    const handleNext = async() => {
        setLoading(true);
        try {
            const listBoarding = await eventDateTransport(event.id, event.event_date_id, values.tickets.qty);

            setBoarding(listBoarding);
            setLoading(false);

            if (listBoarding.length > 0) {
                setType('submit');
                setActiveStep(prev => prev + 1);
            }
            else handleOpen('Não há pontos de embarque');
            
        } catch (error) {
            setLoading(false);
            handleOpen('Hove um erro, tente novamente mais tarde!');
        }
    }

    function handleOpen(messege) {
        setOpenAlert({
            open: true,
            severity: 'error',
            text: messege
        });
    }

    function handleClose() {
        setOpenAlert({
            open: false,
            severity: 'error',
            text: ''
        });
    }

    return (
        <>
            {(steps(props, { boarding })[activeStep].component)}

            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleClose}
            />

            <Grid
                item
                xs={12}
                display={event.has_pcd ? 'flex' : 'none'}
                alignItems="center"
                mt={2}
            >
                <AccessibleForward />
                <Typography fontSize={12} ml={1}>
                    Você tem alguma necessidade especial
                </Typography>
                <Checkbox
                    checked={values.checkedPCD}
                    name="checkedPCD"
                    color="primary"
                    onChange={handleChange}
                />
            </Grid>

            <Grid
                item xs={12}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                gap={2}
                mt={2}
            >
                <Button
                    className={theme.btnSubmit}
                    disabled={disableBtn(values.amount, values)}
                    color="secondary"
                    size="large"
                    type={type}
                    variant="contained"
                    onClick={() => handleNext()}
                >
                    {type === 'button'
                        ? 'Continuar'
                        : `${values.amount.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            })}`
                    }
                    <ChevronRight />
                </Button>

                <Button
                    className={theme.btnSubmit}
                    disabled={activeStep < 1 ? true : false}
                    color="ternary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={handleBack}
                >
                    Anterior
                </Button>

                <Button
                    className={theme.btnSubmit}
                    color="quaternary"
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={() => window.history.back()}
                    sx={{
                        '&:hover': { boxShadow: '0px 5px 10px #fc006599' }
                    }}
                >
                    Voltar
                </Button>
            </Grid>
        </>
    );
}