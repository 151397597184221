import { Alert, Snackbar } from '@mui/material';
import { forwardRef } from 'react';

export const SnackbarAlert = forwardRef((props, ref) => {
    const {
        alertProps,
        handleClose
    } = props;

    return (
        <Snackbar
            ref={ref}
            open={alertProps.open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                variant="filled"
                severity={alertProps.severity}
                onClose={handleClose}
            >
                {alertProps.text}
            </Alert>
        </Snackbar>
    );
});

export function triggerAlert(event, validations, openAlert, closeAlert) {
    const { name, value } = event.target;
    const newAlert = {};
    newAlert[name] = validations[name](value);

    !newAlert[name].valid
        ? openAlert(newAlert[name].text)
        : closeAlert()
}