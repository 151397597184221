import { NavLink } from 'react-router-dom';
import { Button, IconButton, Link, ListItem } from '@mui/material';

export function NavItem({ href, theme, title, ...rest }) {
    
    return (
        <ListItem
            disableGutters
            disablePadding
            {...rest}
        >
            <Button
                className={theme}
                component={NavLink}
                variant="text"
                color="ternary"
                to={href}
            >
                {title}
            </Button>
        </ListItem>
    );
};

export function NavItemLink({ href, theme, title, ...rest }) {
    
    return (
        <ListItem
            disableGutters
            disablePadding
            {...rest}
        >
            <Button
                className={theme}
                component={Link}
                variant="text"
                color="ternary"
                href={href}
            >
                {title}
            </Button>
        </ListItem>
    );
};

export function NavIcon({ href, ico, theme, ...rest }) {
    
    return (
        <ListItem
            disableGutters
            disablePadding
            {...rest}
        >
            <IconButton
                className={theme}
                component={Link}
                color="secondary"
                href={href}
                target="_blank"
            >
                <i className={ico} />
            </IconButton>
        </ListItem>
    );
};