import { useState, useEffect } from 'react';

import {
    Backdrop,
    Box,
    Button,
    Container,
    Fade,
    FormControl,
    FormControlLabel,
    Modal,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';

import layoutPage from '../../theme/pageThemes';

export default function Choise({ values, handleChange, sendCode }) {
    const theme = layoutPage();

    const currentValue = `${values.ddi.replace('0', '').replace('0', '')}${values.tel.replace(/\D/g, '')}`;
    const [open, setOpen] = useState(false);
    function handleClose() {setOpen(!open)}
    
    useEffect(() => values.choise = currentValue, []);

    return (
        <>
            <Button
                variant="text"
                color="btnSendCode"
                onClick={() => setOpen(!open)}
                sx={{ '@media (max-width: 280px)': { width: '100%' } }}
            >
                Clique para enviar o código!
            </Button>

            
            <Modal
                closeAfterTransition
                className={theme.modal}
                open={open}
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Container className={theme.modalContainer} sx={{ maxWidth: 450 }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2
                            }}
                        >
                            <Typography color="primary" textAlign="left">
                                Escolha abaixo como gostaria de receber o token
                            </Typography>

                            <FormControl>
                                <RadioGroup
                                    name="choise"
                                    value={values.choise}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        value={currentValue}
                                        label={`sms: +${currentValue}`}
                                        control={<Radio />}
                                        sx={{ color: '#111' }}
                                    />

                                    <FormControlLabel
                                        value={values.email}
                                        label={values.email}
                                        control={<Radio />}
                                        sx={{ color: '#111' }}
                                    />
                                </RadioGroup>
                            </FormControl>
                            
                            <Button
                                className={theme.btnSubmit}
                                variant="contained"
                                color="secondary"
                                onClick={() => sendCode(values)}
                                sx={{ width: '100%' }}
                            >
                                Continuar
                            </Button>

                            <Button
                                className={theme.btnSubmit}
                                variant="contained"
                                color="ternary"
                                onClick={handleClose}
                                sx={{ width: '100%' }}
                            >
                                Cancelar
                            </Button>
                        </Box>
                    </Container>
                </Fade>
            </Modal>
        </>
    );
}