import { forwardRef, useEffect, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Typography
} from '@mui/material';

import {
    ArrowForwardIosSharp,
    InsertInvitation,
    ExpandMore,
    Info,
    LocationOn,
    LocalOffer
} from '@mui/icons-material';

import { eventData, eventDateTransport } from '../../utils/services/eventData';
import { description } from '../../utils/fx/replaces';
import { styled } from '@mui/styles';

import CardDatesEvents from './CardDatesEvents';
import { sortDate } from '../../utils/fx/getDate';

const Accor = styled(props => <Accordion elevation={1} {...props} />)(
    ({ theme }) => ({
        backgroundColor: theme.palette.background.accordion,
        color: theme.palette.text.ternary,
        fontWeight: 'bold',
    
        '& .Mui-expanded': {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.text.primary,
            borderRadius: '4px 4px 0 0',
            margin: 0,
    
            '& .MuiSvgIcon-root': {
                color: theme.palette.text.primary
            }
        },
    })
  );

const AccorSumm = styled(props => (
    <AccordionSummary
        {...props}
        expandIcon={
            <ArrowForwardIosSharp sx={{ fontSize: '0.9rem', transform: 'rotate(90deg)' }} />
        }
    />
))(({ theme }) => ({
    flexDirection: 'row',
    
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary
        }
    },
  
    '& .MuiSvgIcon-root': { color: theme.palette.text.ternary },
  
    '& .MuiTypography-root': {
        fontSize: 18,
        padding: '2px 0',
    },
}));

const AccorDet = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.background.accordion,
    color: theme.palette.text.ternary,
    padding: 16,
    borderRadius: '0 0 4px 4px'
}));

const AccordionInfos = forwardRef(({ event }, ref) => {
    const [eventCurrent, setEventCurrent] = useState(false);
    const [boarding, setBoarding] = useState([]);
    
    const [colors, setColors] = useState({
        description: 'whitesmoke',
        date: 'whitesmoke',
        local: 'whitesmoke',
        boarding: 'whitesmoke'
    });

    function handleChange(element) {
        let color;
        
        colors[element] === 'whitesmoke'
            ? color = '#000'
            : color = 'whitesmoke'

        setColors({
            ...color,
            [element]: color
        });
    }

    async function loadAttributes() {
        const data = await eventData(event._id);
        const transport = await eventDateTransport(event._id, data?.dates[0]._id);

        if (transport.length > 0) setBoarding(transport.map(item => item.boarding_name).sort().join(', '));
        
        setEventCurrent(data);
    }

    useEffect(() => loadAttributes(), []);

    return (
        <>
            {
                eventCurrent &&
                    <Grid
                        ref={ref}
                        container
                        spacing={1}
                    >
                        {
                            (eventCurrent.event_description || eventCurrent.event_short_slogan) &&
                                <Grid item xs={12}>
                                    <Accor onChange={() => handleChange('description')}>
                                        <AccorSumm
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Info sx={{ mr: 2, color: colors.description }} />
                                                <Typography>Informações</Typography>
                                            </Box>
                                        </AccorSumm>
                                        <AccorDet>
                                            {
                                                description(eventCurrent)
                                                    .map((item, i) => (
                                                        <Typography
                                                            key={i}
                                                            textAlign="justify"
                                                            mb={2}
                                                        >
                                                            {item}
                                                        </Typography>
                                                    ))
                                            }

                                            {
                                                eventCurrent.know_more.title &&
                                                    <>
                                                        <Typography
                                                            color="secondary"
                                                            variant="h6"
                                                            mb={1}
                                                        >
                                                            Saiba mais   
                                                        </Typography>

                                                        <Typography
                                                            color='whitesmoke'
                                                            variant="h6"
                                                            textAlign="justify"
                                                            fontWeight={400}
                                                        >
                                                            <Box
                                                                component={'a'}
                                                                href={`${eventCurrent.know_more.link}`}
                                                                target="_blank"
                                                                style={{
                                                                    color: 'whitesmoke',
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'none'
                                                                }}
                                                            >
                                                                {eventCurrent.know_more.title}
                                                            </Box>
                                                        </Typography>
                                                    </>
                                            }
                                        </AccorDet>
                                    </Accor>
                                </Grid>
                        }

                        <Grid item xs={12}>
                            <Accor onChange={() => handleChange('date')}>
                                <AccorSumm
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <InsertInvitation sx={{ mr: 2, color: colors.date }} />
                                        <Typography>Datas</Typography>
                                    </Box>
                                </AccorSumm>
                                <AccorDet>
                                    {
                                        eventCurrent.dates.length < 1
                                            ? <Typography>Em breve</Typography>
                                            :
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    display="flex"
                                                    // m="0 auto"
                                                >
                                                    {console.log(eventCurrent.dates)}
                                                    {
                                                        eventCurrent.dates.sort((a, b) => sortDate(a, b)).map((date, i) => (
                                                            <CardDatesEvents
                                                                key={i}
                                                                date={date}
                                                                event={event}
                                                            />
                                                        ))
                                                    }
                                                </Grid>
                                    }
                                </AccorDet>
                            </Accor>
                        </Grid>

                        {
                            boarding.length > 0 &&
                                <Grid item xs={12}>
                                    <Accor onChange={() => handleChange('boarding')}>
                                        <AccorSumm
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <LocalOffer sx={{ mr: 2, color: colors.boarding }} />
                                                <Typography>Pontos de Embarque</Typography>
                                            </Box>
                                        </AccorSumm>

                                        <AccorDet>
                                            <Typography textAlign="justify">{boarding}</Typography>
                                        </AccorDet>
                                    </Accor>
                                </Grid>
                        }

                        {
                            eventCurrent.dates.length > 0 &&
                                <Grid item xs={12}>
                                    <Accor onChange={() => handleChange('local')}>
                                        <AccorSumm
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <LocationOn sx={{ mr: 2, color: colors.local }} />
                                                <Typography>Localização</Typography>
                                            </Box>
                                        </AccorSumm>
                                            <AccorDet>
                                                {/* <Typography>
                                                    {`${eventCurrent.event_address.street}, ${eventCurrent.event_address.street_number}`}
                                                </Typography>

                                                <Typography>
                                                    {`CEP.: ${eventCurrent.event_address.cep}`}
                                                </Typography>

                                                <Typography>
                                                    {`${eventCurrent.event_address.neighborhood} - ${eventCurrent.event_address.city} - ${eventCurrent.event_address.state}`}
                                                </Typography> */}
                                                
                                                <Box className="google-map">
                                                    <Box
                                                        component="iframe"
                                                        width="100%"
                                                        height={400}
                                                        style={{ border: 0 }}
                                                        referrerpolicy="no-referrer-when-downgrade"
                                                        src={eventCurrent.event_address.location.location_url}
                                                    />
                                                </Box>
                                            </AccorDet>
                                    </Accor>
                                </Grid>
                        }
                    </Grid>
            }
        </>
    );
});

export default AccordionInfos;