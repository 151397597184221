import { useEffect, useState } from 'react';
import { useDeviceData } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Menu } from '@mui/icons-material';
import { AppBar, Box, Hidden, IconButton, List, ListItem, Toolbar } from '@mui/material';
import { Logo } from './Logos';

// import { androidHref ,appleHref, urbanky } from '../utils/services/urls';
import { login, logOut } from '../utils/services/access';

import layoutPage from '../theme/pageThemes';
import { NavIcon, NavItem, NavItemLink } from './NavItem';
import { Fragment } from 'react';
import ModalExit from './ModalExit';

const items = [
    { href: 'https://urbanky.com.br/', title: 'home' },
    { href: '/app/eventos', title: 'eventos' },
    { href: 'https://urbanky.com.br/faq.html', title: 'dúvidas' },
    { href: 'https://urbanky.com.br/contato.html', title: 'contato' },
    { href: '/app/vouchers', title: 'vouchers' }
];

const icons = [
  { href: 'https://www.facebook.com/urbanky.br', ico: 'fa fa-facebook' },
  { href: 'https://twitter.com/urbanky_com_vc', ico: 'fa fa-twitter' },
  { href: 'https://www.instagram.com/urbanky.com.vc', ico: 'fa fa-instagram' }
];

export default function AppNavbar({ active, logged, onLogged, onMobileNavOpen, ...rest }) {
    const theme = layoutPage();
    const location = useLocation();
    const navigate = useNavigate();
    const deviceInfo = useDeviceData();

    useEffect(() => {
        onLogged(login());
    }, []);

    return (
        <AppBar
            elevation={0}
            {...rest}
            sx={{
                height: 64,
                display: 'flex',
                alignItems: 'center',
                padding: '0 min(32px, 5vw)',
                transition: '.75s all',
                background: !active
                                ? `linear-gradient(
                                      90deg,
                                      #000000f0,
                                      #00000052
                                  )`
                                : '#000'
            }}
        >
            <Toolbar
                sx={{
                    gap: 2,
                    width: '100%',
                    height: '100%',
                    maxWidth: 1140,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box minWidth={100}>
                    <Logo width="100%" height={30} />
                </Box>

                <List
                    sx={{
                        p: 0,
                        gap: .5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Hidden mdDown>
                        {
                            items.map((item, i) => (
                                <Fragment key={i}>
                                    {
                                        item.title !== 'vouchers'
                                            ? item.title !== 'eventos'
                                                ? <NavItemLink href={item.href} theme={theme.navPath} title={item.title} />

                                                : location.pathname !== '/app/eventos' &&
                                                  location.pathname !== '/' &&
                                                  item.title === 'eventos' &&
                                                    <NavItem href={item.href} theme={theme.navPath} title={item.title} />

                                            : logged &&
                                                <NavItem href={item.href} theme={theme.navPath} title={item.title} />
                                    }
                                </Fragment>
                            ))
                        }

                        {
                            logged && <ModalExit type="item" />
                        }
                    </Hidden>

                    {
                        !logged && location.pathname !== '/app/login' &&
                            <NavItem
                                href="/app/login"
                                title="login"
                                theme={theme.navLogin}
                                // onClick={() => {
                                //     if (deviceInfo.os.name.toLowerCase() === 'ios') window.location.href = appleHref;
                                //     else if (deviceInfo.os.name.toLowerCase() === 'android') window.location.href = androidHref;
                                // }}
                            />
                    }

                    <Hidden mdUp>
                        <IconButton
                            color="secondary"
                            onClick={onMobileNavOpen}
                            sx={{ ml: 'min(24px, 2vw)', p: '0' }}
                        >
                                <Menu />
                        </IconButton>
                    </Hidden>
                </List>

                <Hidden mdDown>
                    <Box display="flex" alignItems="center" gap={1}>
                        {
                            icons.map((icon, i) => (
                                <Fragment key={i}> 
                                    <NavIcon href={icon.href} ico={icon.ico} theme={theme.navIcon} />
                                </Fragment>
                            ))
                        }
                    </Box>
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}