import axios from 'axios';

const timeout = 5000;

export default async function appMaintenance() {
    // const config = {
    //     url: `${process.env.REACT_APP_API_PAY}/v1/variable`,
    //     method: 'get',
    //     timeout: timeout,
    //     headers: { client_token: process.env.REACT_APP_CLIENT_TOKEN }
    // }

    // return axios(config)
    // .then(({ data }) => data.data[0].has_maintenance);
    return false;
}