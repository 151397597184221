import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@mui/icons-material';
import { Backdrop, Box, Button, Fade, ListItem, Modal, Typography } from '@mui/material';
import layoutPage from '../theme/pageThemes';
import { logOut } from '../utils/services/access';

export default function ModalExit({ type = 'button' }) {
    const theme = layoutPage();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    function handleClose() { setOpen(!open) }

    return (
        <>
            {
                type === 'item'
                    ?
                        <ListItem
                            disableGutters
                            disablePadding
                            onClick={() => handleClose()}
                            sx={{
                                cursor: 'pointer',
                                fontFamily: '"Roboto", sans-serif',

                                '& button': { gap: .8, p: '8px 16px' }
                            }}
                        >
                            <Button
                                className={theme.navPath}
                                color="secondary"
                            >
                                <Input/>
                                SAIR
                            </Button>
                        </ListItem>

                    : 
                        <Button
                            className={theme.navPath}
                            color="ternary"
                            sx={{ gap: 2 }}
                            onClick={() => handleClose()}
                        >
                            <Input />
                            SAIR
                        </Button>
            }

            <Box>
                <Modal
                    className={theme.modal}
                    closeAfterTransition
                    open={open}
                    onClose={handleClose}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ timeout: 500 }}
                >
                    <Fade
                        in={open}
                        sx={{
                            borderRadius: 4,
                            padding: "21px 16px",
                            backgroundColor: "#060606",
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    padding: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: 5,
                                }}
                            >
                                <Typography variant="h1">Deseja sair?</Typography>

                                <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" gap={2}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            logOut();
                                            navigate('/');
                                        }}
                                    >
                                        sim
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleClose()}
                                    >
                                        não
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </Box>
        </>
    );
}