import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useErrors } from '../../utils/hooks/useErrors';
import { Box, FormControl } from '@mui/material';
import { boardingValidation, hourValidation } from '../../utils/validations/buyValidations';
import { SnackbarAlert, triggerAlert } from '../ShowAlerts';
import BoardingPoints from './options/BoardingPoints';
import BoardingTrips from './options/BoardingTrips';

export default function OptionsVoucher(props) {
    const { amount, boarding, handleChange, theme, values } = props;

    const componentRef = useRef();

    const [openAlert, setOpenAlert] = useState({
        open: false,
        severity: 'error',
        text: ''
    });

    const validations = useContext(
        createContext({
            boarding_point_id: boardingValidation,
            trip_id: hourValidation
        })
    );

    const [errors, fieldsValidation] = useErrors(validations);

    function handleBlur(e) {
        fieldsValidation(e);
        triggerAlert(e, validations, handleOpen, handleClose);
    }

    function handleOpen(messege) {
        setOpenAlert({
            open: true,
            severity: 'error',
            text: messege
        });
    }

    function handleClose() {
        setOpenAlert({
            open: false,
            severity: 'error',
            text: ''
        });
    }

    // async function getBoarding() {
    //     const listBoarding = await eventDateTransport(event.id, event.event_date_id, values.tickets.qty);
    //     setBoarding(listBoarding);
    //     console.log(listBoarding)
    // }

    useEffect(() => {
        values.trip_id = '';
    }, [values.boarding_point_id]);

    return (
        <FormControl sx={{ mt: 3, minWidth: '100%' }}>
            <SnackbarAlert
                ref={componentRef}
                alertProps={openAlert}
                handleClose={handleClose}
            />
            <Box sx={{ mt: -2 }}>
                <BoardingPoints
                    amount={amount}
                    boarding={boarding}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    theme={theme}
                    values={values}
                />
            </Box>
            <Box sx={{ mt: 2 }}>
                <BoardingTrips
                    amount={amount}
                    boarding={boarding}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    theme={theme}
                    values={values}
                />
            </Box>
        </FormControl>
    );
}